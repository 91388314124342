(function () {
    angular.module('alterWebApp').directive('verticalFiltersDirective', ($rootScope, $timeout, $window, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                items: '=',
                current: '=',
                callback: '=',
                others: '=',
                year: '='
            },
            templateUrl: 'app/shared/vertical-filters/template.html',
            link: (scope, element) => {
                if (typeof scope.year !== 'undefined') {
                    scope.currentYear = moment().format('YYYY');
                    scope.currentMonth = moment().format('M');
                }

                scope.getClass = (className) => {
                    return `${className}`;
                };
            }
        };
    });
})();
