(function () {
    angular.module('alterWebApp').service('ServicesService', ServicesService);

    function ServicesService(ApiService) {
        const baseUrl = '/api/v1/services';
        const categoriesBaseUrl = '/api/v1/service-category';

        this.getCategories = function () {
            return ApiService.getPublic(categoriesBaseUrl);
        };

        this.search = function (query) {
            return ApiService.query(baseUrl + '/paged', query);
        };
    }
})();
