(function () {
    angular.module('alterWebApp').directive('carouselDirective', ($rootScope, $state, $timeout, $window, GeneralConst, FileService) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                items: '=',
                video: '=',
                navObj: '='
            },
            templateUrl: 'app/shared/carousel/template.html',
            link: (scope, element) => {
                if (typeof element[0] === 'undefined') {
                    return;
                }

                scope.sliderHeight = 0;
                scope.current = null;
                scope.images = element[0].getElementsByTagName('img');
                scope.titleWrap = document.getElementById('titleWrap');
                scope.sliderOpts = {
                    slideShowEl: element[0].querySelector('.slideshow'),
                    autoplay: 4000,
                    itemsPerPage: 1
                };

                scope.getLandscapeSrc = (item) => {
                    const image = item.image || item.coverImage;
                    if (item.local) {
                        return image;
                    }

                    if (!image || typeof image === 'uindefined') {
                        return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.LANDSCAPE);
                    }

                    return FileService.getLandscape(image);
                };

                scope.formatIndex = (index) => {
                    let s = index + '';
                    while (s.length < 2) {
                        s = '0' + s;
                    }
                    return s;
                };

                scope.formatDate = (date) => {
                    const str = {};

                    if (!date) {
                        return '&';
                    }

                    str.day = moment(date).format('DD');
                    str.month = moment(date).format('MMM');

                    return str;
                };

                scope.getCurrentUrl = () => {
                    if (!scope.current || (!scope.current.state && !scope.current.href)) {
                        return null;
                    }

                    if (scope.current.href) {
                        return scope.current.href;
                    }

                    return $state.href(scope.current.state, { slug: scope.current.slug });
                };

                scope.$on('$destroy', () => {
                    $window.removeEventListener('resize', setSliderHeight);
                });

                function setSliderHeight() {
                    const nav = document.getElementById('mainNav');

                    $timeout(() => {
                        const navClientHeight = (nav ? nav.clientHeight : 0);
                        const navH = ($state.current.name === 'app.home' ? 0 : navClientHeight);
                        const sliderNavH = document.getElementById('sliderNav').clientHeight;

                        if (!$rootScope.isMobile || ($rootScope.isMobile && $state.current.name !== 'app.home')) {
                            scope.sliderHeight = window.innerHeight - navH - sliderNavH;
                        } 
                        else {
                            console.log('w', window.innerHeight);
                            scope.sliderHeight = window.innerHeight;
                        }

                        setIndexNavPosVertically(navH);
                    });
                }

                function setIndexNavPosVertically(navHeight) {
                    if (typeof document.getElementsByClassName('slide-title-wrap')[0] === 'undefined') {
                        return;
                    }

                    if (!$rootScope.isMobile) {
                        $timeout(() => {
                            const navBox = document.getElementById('indexNavbox');
                            const textWrapTop = scope.titleWrap.getBoundingClientRect().top;
                            navBox.style.top = (textWrapTop + scope.titleWrap.clientHeight - navHeight + 30) + 'px';
                        });
                    }
                }

                class Slide {
                    constructor(el, index, settings) {
                        this.DOM = { el };
                        this.slideIndex = index;

                        this.settings = {};
                        Object.assign(this.settings, settings);

                        this.DOM.wrap = this.DOM.el.querySelector('.slide-wrap');
                        this.DOM.img = this.DOM.wrap.querySelector('.slide-img');

                        this.config = {
                            animation: {
                                duration: 1.2,
                                eause: Expo.easeInOut
                            }
                        };
                    }

                    setCurrent(isCurrent = true) {
                        this.DOM.el.classList[isCurrent ? 'add' : 'remove']('slide--current');
                    }

                    hide(direction) {
                        return this.toggle('hide', direction);
                    }

                    show(direction) {
                        this.DOM.el.style.zIndex = 998;
                        return this.toggle('show', direction);
                    }

                    toggle(action, direction) {
                        return new Promise((resolve) => {
                            if (action === 'show') {
                                TweenMax.to(this.DOM.wrap, this.config.animation.duration, {
                                    ease: this.config.animation.ease,
                                    startAt: { x: '100%' },
                                    x: '0%'
                                });

                                TweenMax.to(scope.titleWrap, 0.5, {
                                    ease: 'Back.easeIn',
                                    y: direction === 'right' ? '-100%' : '100%',
                                    opacity: 0,
                                    onComplete: () => {
                                        $timeout(() => {
                                            scope.current = scope.items[this.slideIndex];
                                        });

                                        TweenMax.to(scope.titleWrap, 2.2, {
                                            ease: 'Power4.easeOut',
                                            startAt: { y: direction === 'right' ? '20%' : '-20%', opacity: 0 },
                                            y: '0%',
                                            opacity: 1
                                        });
                                    }
                                });
                            }

                            TweenMax.to(this.DOM.img, this.config.animation.duration, {
                                ease: this.config.animation.ease,
                                startAt: action === 'hide' ? {} : { x: direction === 'right' ? '-100%' : '100%', scale: 1.1 },
                                x: '0%',
                                scale: action === 'hide' ? 1.1 : 1,
                                onStart: () => {
                                    if (action === 'hide') {
                                        this.DOM.img.style.transformOrigin = direction === 'right' ? '100% 50%' : '0% 50%';
                                    }
                                    else {
                                        this.DOM.img.style.transformOrigin = direction === 'right' ? '0% 50%' : '100% 50%';
                                    }

                                    this.DOM.el.style.opacity = 1;
                                },
                                onComplete: () => {
                                    this.DOM.el.style.zIndex = 99;
                                    this.DOM.el.style.opacity = action === 'hide' ? 0 : 1;
                                    resolve();
                                }
                            });
                        });
                    }
                }

                class Navigation {
                    constructor(el, settings) {
                        this.DOM = {
                            el,
                            indexBox: document.getElementById('indexNavbox'),
                            navItems: el.getElementsByClassName('nav-item')
                        };

                        this.settings = {
                            next: () => false,
                            prev: () => false,
                            navigateTo: () => false,
                            maxItems: 4,
                            totalSlides: 0
                        };

                        Object.assign(this.settings, settings); // override default settings

                        this.DOM.pagination = {
                            current: this.DOM.indexBox.querySelector('.progress-nav-label-current'),
                            total: this.DOM.indexBox.querySelector('.progress-nav-label-total'),
                            indexDash: this.DOM.indexBox.querySelector('.progress-nav-label-dash-current'),
                            dash: document.getElementById('sliderNavSelector')
                        };

                        this.DOM.navigationItems = this.DOM.el.getElementsByClassName('nav-item');
                        this.bindEvents();
                    }

                    // Updates the current page element value with animation.
                    setCurrent(val, direction, previous) {
                        const index = val - 1;

                        TweenMax.to(this.DOM.pagination.current, 0.4, {
                            ease: 'Back.easeIn',
                            y: direction === 'right' ? '-100%' : '100%',
                            opacity: 0,
                            onComplete: () => {
                                this.DOM.pagination.current.innerHTML = scope.formatIndex(val);
                                if (this.DOM.pagination.indexDash) {
                                    TweenMax.to(this.DOM.pagination.indexDash, 0.2, {
                                        ease: 'Expo.easeOut',
                                        left: ((100 / this.settings.totalSlides) * index) + '%'
                                    });
                                }

                                if (this.DOM.pagination.dash) {
                                    TweenMax.to(this.DOM.pagination.dash, 0.2, {
                                        ease: 'Expo.easeOut',
                                        left: ((100 / this.settings.maxItems) * index) + '%'
                                    });
                                }

                                if (this.DOM.pagination.current) {
                                    TweenMax.to(this.DOM.pagination.current, 0.8, {
                                        ease: 'Expo.easeOut',
                                        startAt: { y: direction === 'right' ? '50%' : '-50%', opacity: 0 },
                                        y: '0%',
                                        opacity: 1
                                    });
                                }

                                if (!$rootScope.isMobile) {
                                    this.DOM.navItems[index].classList.add('current');
                                    this.DOM.navItems[previous].classList.remove('current');
                                }
                            }
                        });
                    }

                    // Sets the total pages value.
                    setTotal(val) {
                        this.DOM.pagination.total.innerHTML = scope.formatIndex(val);
                        this.settings.totalSlides = parseInt(val, 10);
                    }

                    // Initialize the events on the next/prev controls.
                    bindEvents() {
                        $window.addEventListener('resize', setSliderHeight);
                        if (this.DOM.navigationItems.length) {
                            for (let i = 0; i < this.DOM.navigationItems.length; i += 1) {
                                this.DOM.navigationItems[i].addEventListener('click', (event) => {
                                    this.settings.navigateTo(i);
                                });
                            }
                        }
                    }
                }

                // The Slideshow class.
                class Slideshow {
                    constructor(el) {
                        this.DOM = { el };
                        this.autoplayTimer = null;
                        this.navigation = new Navigation(document.getElementById('sliderNav'), {
                            next: () => this.navigate('right'),
                            prev: () => this.navigate('left'),
                            navigateTo: (index) => {
                                clearInterval(this.autoplayTimer);
                                this.navigate(index);
                                this.autoplay();
                            }
                        });

                        this.slides = [];
                        Array.from(this.DOM.el.querySelectorAll('.slide')).forEach((slideEl, pos) => {
                            this.slides.push(new Slide(slideEl, pos));
                        });

                        this.slidesTotal = this.slides.length;
                        this.navigation.setTotal(this.slidesTotal);
                        if (this.slidesTotal < 2) {
                            if (this.slides.length && typeof this.slides[0].DOM.el !== 'undefined') {
                                this.slides[0].DOM.el.classList.add('slide--current');
                            }
                            return false;
                        }

                        this.current = 0;
                        this.init();
                    }

                    init() {
                        console.log(this.slides);
                        this.slides[this.current].setCurrent();
                        this.autoplay();

                        if ($rootScope.isMobile) {
                            this.initSwiper();
                        }

                        $timeout(() => {
                            $window.dispatchEvent(new Event('resize'));
                        });
                    }

                    autoplay() {
                        this.autoplayTimer = setInterval(() => {
                            this.navigate(this.current + 1);
                        }, scope.sliderOpts.autoplay);
                    }

                    initSwiper() {
                        const swiperArea = element[0].querySelector('.slideshow');
                        this.sliderManager = new Hammer.Manager(swiperArea, {
                            touchAction: 'auto',
                            recognizers: [
                                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
                            ]
                        });

                        this.sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
                        scope.draggable = true;

                        this.sliderManager.on('panright panleft', (e) => {
                            if (swiperArea.classList.contains('disabled')) {
                                return;
                            }

                            if (e.type === 'panleft') {
                                this.navigate(this.current + scope.sliderOpts.itemsPerPage);
                                clearInterval(this.autoplayTimer);
                                this.autoplay();
                            }
                            else if (e.type === 'panright') {
                                this.navigate(this.current - scope.sliderOpts.itemsPerPage);
                                clearInterval(this.autoplayTimer);
                                this.autoplay();
                            }

                            swiperArea.classList.remove('disabled');
                        });
                    }

                    navigate(nextSlide) {
                        let direction = null;
                        let nextSlidePos = null;

                        if (scope.isAnimating || this.current === nextSlide) {
                            return;
                        }

                        scope.isAnimating = true;
                        nextSlidePos = nextSlide;

                        if (nextSlide > this.slidesTotal - 1) {
                            nextSlidePos = 0;
                        }
                        else if (nextSlide < 0) {
                            nextSlidePos = this.slidesTotal - 1;
                        }

                        if (this.current < nextSlide) {
                            direction = 'right';
                        }
                        else {
                            direction = 'left';
                        }

                        this.navigation.setCurrent(nextSlidePos + 1, direction, this.current);

                        Promise.all([this.slides[this.current].hide(direction), this.slides[nextSlidePos].show(direction)]).then(() => {
                            this.slides[this.current].setCurrent(false);
                            this.current = nextSlidePos;
                            this.slides[this.current].setCurrent();
                            scope.isAnimating = false;
                        });
                    }
                }


                scope.$watch('items', init, true);
                function init() {
                    if (!scope.items.length) {
                        return;
                    }

                    scope.current = scope.items[0];

                    $timeout(() => {
                        const slideshow = new Slideshow(scope.sliderOpts.slideShowEl);
                        imagesLoaded(scope.images, { background: true }, () => {
                            document.body.classList.remove('loading')
                        });
                    });
                }
            }
        };
    });
})();
