(function () {
    angular.module('alterWebApp').directive('mapDirective', ($rootScope, $state, MapsService, Utils, $timeout) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                config: '=',
                markers: '=',
                callback: '='
            },
            templateUrl: 'app/shared/map/template.html',
            link: (scope, element, attrs) => {
                const self = this;
                self.mapInstance = null;
                self.mapOptions = {
                    zoom: 12,
                    minZoom: 9,
                    maxZoom: 16,
                    center: {
                        lat: 39.1984112,
                        lng: -7.6675259
                    },
                    clickableIcons: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    scrollwheel: true,
                    zoomControl: false,
                    fullscreenControl: false
                };

                scope.$watch('config', init, true);

                function init() {
                    if (!scope.config) {
                        return;
                    }

                    Object.assign(self.mapOptions, scope.config);
                    self.currentMapData = null;
                    self.markersList = [];

                    MapsService.load().then(() => {
                        if (!document.getElementById(scope.config.id)) {
                            return;
                        }

                        self.mapInstance = new google.maps.Map(document.getElementById(scope.config.id), self.mapOptions);
                        if (!angular.isUndefined(self.mapOptions.limits)) {
                            setMapBoundsLimit();
                        }

                        if (!angular.isUndefined(scope.markers)) {
                            loadMarkers(scope.markers);
                        }
                    }, mapsLoadError);
                }

                scope.$on('mapDirective:loadLayer', (event, points) => {
                    if (!Array.isArray(points)) {
                        return;
                    }

                    deleteMarkers();
                    loadMarkers(points);
                });

                scope.$on('mapDirective:setSelectedPoint', (event, point) => {
                    if (point === null) {
                        resetMarkerVisibleStatus();
                    }
                    else {
                        self.markersList.forEach((m) => {
                            if (typeof m.properties !== 'undefined' && m.properties.pointId === point.getId()) {
                                m.set('labelVisible', true);
                                self.mapInstance.panTo(new google.maps.LatLng(m.position.lat(), m.position.lng()));
                            }
                            else {
                                m.set('labelVisible', false);
                            }
                        });
                    }
                });

                scope.$on('mapDirective:removeAllMarkers', (event) => {
                    deleteMarkers();
                });

                scope.$on('mapDirective:deleteMapPoint', (event, point) => {
                    if (!point) {
                        return;
                    }

                    deleteMarker(point);
                });

                function deleteMarkers() {
                    self.markersList.forEach((m) => {
                        m.setMap(null);
                    });
                }

                function deleteMarker(point) {
                    let index = null;
                    self.markersList.forEach((m, i) => {
                        if (m.properties.pointId === point.getId()) {
                            index = i;
                            m.setMap(null);
                        }
                    });

                    if (index != null) {
                        self.markersList.splice(index, 1);
                    }
                }

                function resetMarkerVisibleStatus() {
                    self.markersList.forEach((m) => {
                        m.set('labelVisible', false);
                    });
                }

                function loadMarkers(points) {
                    self.markersList = points.map((point, index) => {
                        const marker = createMarker(point);
                        if (marker === null) {
                            return false;
                        }
                        if (marker.properties) {
                            markerClickHandler(marker);
                        }

                        return marker;
                    });

                    fitMapBounds(points);
                }

                function createMarker(point) {
                    if (!point || typeof point === 'undefined') {
                        return null;
                    }

                    let coords = null;
                    if (typeof point.geometry !== 'undefined') {
                        coords = point.geometry.coordinates;
                    }
                    else {
                        coords = point.coordinates;
                    }

                    if (!coords) {
                        return null;
                    }

                    const latLng = new google.maps.LatLng(coords[1], coords[0]);

                    const markerConfig = {
                        position: latLng,
                        map: self.mapInstance,
                        labelClass: 'custom-class-for-label',
                        labelInBackground: true,
                        labelVisible: false
                    };

                    if (typeof point.properties !== 'undefined') {
                        markerConfig.properties = point.properties;
                        markerConfig.labelAnchor = new google.maps.Point(-15, 0);
                        markerConfig.labelContent = '<div class="flex items-center title">' + point.properties.title + '</div>';
                    }

                    const marker = new MarkerWithLabel(markerConfig);

                    return marker;
                }

                function markerClickHandler(marker) {
                    google.maps.event.addListener(marker, 'click', () => {
                        resetMarkerVisibleStatus();
                        marker.set('labelVisible', true);

                        scope.$apply(() => {
                            scope.callback(null, marker.properties.pointId);
                        });
                    });
                }

                function fitMapBounds(points) {
                    if (!points.length) {
                        return;
                    }

                    const bounds = new google.maps.LatLngBounds();
                    points.forEach((point) => {
                        if ((point.geometry && point.geometry.coordinates) || (point.coordinates && point.coordinates.length)) {
                            const lat = (typeof point.geometry !== 'undefined' ? point.geometry.coordinates[1] : point.coordinates[1]);
                            const lng = (typeof point.geometry !== 'undefined' ? point.geometry.coordinates[0] : point.coordinates[0]);
                            const latLng = new google.maps.LatLng(lat, lng);
                            bounds.extend(latLng);
                        }
                    });

                    self.mapInstance.fitBounds(bounds);
                }

                function setMapBoundsLimit() {
                    const strictBounds = new google.maps.LatLngBounds(
                        new google.maps.LatLng(self.mapOptions.limits.sw[1], self.mapOptions.limits.sw[0]),
                        new google.maps.LatLng(self.mapOptions.limits.ne[1], self.mapOptions.limits.ne[0])
                    );

                    let lastValidCenter = self.mapInstance.getCenter();

                    google.maps.event.addListener(self.mapInstance, 'center_changed', () => {
                        const northEast = self.mapInstance.getBounds().getNorthEast();
                        const southWest = self.mapInstance.getBounds().getSouthWest();

                        if (strictBounds.contains(northEast) && strictBounds.contains(southWest)) {
                            lastValidCenter = self.mapInstance.getCenter();
                            return;
                        }

                        self.mapInstance.panTo(lastValidCenter);
                    });
                }

                function mapsLoadError(err) {
                    console.log('mapsLoadError', err);
                }
            }
        };
    });
})();
