(function () {
    angular.module('alterWebApp')
        .config(appConfig)
        .run(appRun);

    function appConfig($httpProvider) {
        $httpProvider.interceptors.push('RequestInterceptor');
    }

    function appRun($rootScope, $window, ApiService) {
        $rootScope.menuItems = [];
        $rootScope.stringsCollection = {};
        $rootScope.currentLanguage = null;
        $rootScope.ENV = {};
        $rootScope.defaultState = 'app.home';
        $rootScope.baseState = 'app.home';

        if (window) {
            Object.assign($rootScope.ENV, window.__env);
        }

        $rootScope.mapConfig = {
            key: $rootScope.ENV.gmapsKey,
            version: 3.34
        };

        if (typeof $rootScope.ENV.apiHost !== 'undefined') {
            ApiService.setHost($rootScope.ENV.apiHost);
        }
    }
})();
