(function () {
    angular.module('alterWebApp').service('PointsService', PointsService);

    function PointsService(ApiService) {
        const baseUrl = '/api/v1/points-of-interest';

        this.get = function (slug) {
            return ApiService.getPublic(baseUrl + '/' + slug);
        };

        this.getMuseums = function () {
            return ApiService.getPublic(baseUrl + '/museum');
        };

        this.getHighlightedPoints = function () {
            return ApiService.getPublic(baseUrl + '/highlighted');
        };

        this.getPointsTree = function (query) {
            return ApiService.query(baseUrl + '/tree', query);
        };

        this.getChildren = function (query) {
            return ApiService.query(baseUrl + '/by-parent', query);
        };

        this.getChildrenFloor = function (query) {
            return ApiService.query(baseUrl + '/by-floor', query);
        };
    }
})();
