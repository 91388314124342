(function () {
    angular.module('alterWebApp').directive('dropDownDirective', ($rootScope, $state, StorageService) => {
        return {
            restrict: 'E',
            transclude: false,
            templateUrl: 'app/shared/menu/dropdown.html',
            scope: {
                items: '='
            },
            replace: true,
            link: (scope) => {}
        };
    });
})();
