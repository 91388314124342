(function () {
    angular.module('alterWebApp').controller('contactsNucleusController', ContactsNucleusController);

    function ContactsNucleusController($scope, $state, $rootScope, $timeout, Utils, LanguageService) {
        const self = this;
        $scope.point = null;
        $scope.config = {};
        $scope.markers = [];
        $scope.isReady = false;

        self.contacts = {};
        self.initView = () => {
            if ($scope.$parent.point) {
                Object.assign($scope.config, $rootScope.mapConfig);
                $scope.point = $scope.$parent.point;
                if (!$scope.point.location) {
                    return;
                }

                $scope.config.center = {};
                if ($scope.point.location.coordinates.length) {
                    $scope.config.center.lng = $scope.point.location.coordinates[0];
                    $scope.config.center.lat = $scope.point.location.coordinates[1];
                    $scope.markers.push($scope.point.location);
                }

                if (typeof $rootScope.ENV.gmapsColoredStyle !== 'undefined') {
                    $scope.config.styles = $rootScope.ENV.gmapsColoredStyle;
                }

                $scope.config.id = 'map' + $scope.point.id + Math.random().toString(36).substring(3);
                $timeout(() => {
                    $scope.isReady = true;
                });
            }
        };
    }
})();
