(function () {
    angular.module('alterWebApp').factory('Point', PointModel);

    function PointModel(Utils, $state, $rootScope, LanguageService, GeneralConst) {
        function Point(data) {
            if (data) {
                this.setData(data);
            }
        }

        Point.prototype = {
            setData(data) {
                this.id = data._id;
                this.slug = Utils.initValue(data.slug);
                this.title = Utils.initValue(data.title);
                this.description = Utils.initValue(data.description);
                this.excerpt = Utils.initValue(data.excerpt) ? Utils.initValue(data.excerpt) : this.description;
                this.icon = Utils.initValue(data.icon);
                this.contacts = data.contacts && data.contacts.length ? data.contacts : [];
                this.pricing = Utils.initValue(data.pricing);
                this.gallery = Utils.initValue(data.gallery);
                this.location = Utils.initValue(data.location);
                this.childs = Utils.initValue(data.childs);
                this.relatedPoints = Utils.initValue(data.relatedPoints);
                this.parent = Utils.initValue(data.parent);

                this.kind = Utils.initValue(data.kind);
                this.highlighted = Utils.initValue(data.highlighted);
                this.location = Utils.initValue(data.location);
                this.level = Utils.initValue(data.level);
                this.order = Utils.initValue(data.order);
                this.createdAt = Utils.initValue(data.createdAt);
                this.coverImage = Utils.initValue(data.coverImage) ? data.coverImage.file : this.getCoverImage();

                if (this.kind === GeneralConst.NUCLEUS_POINT_TYPE) {
                    this.state = 'app.nucleusMain.nucleusVisit';
                }
                else {
                    this.state = 'app.pointProfile';
                }

                if (this.childs && this.childs.length) {
                    for (let i = 0; i < this.childs.length; i += 1) {
                        const p = new Point(this.childs[i]);
                        p.href = Utils.buildSref(p, LanguageService.getCurrentLanguage());
                        this.childs[i] = p;
                    }
                }

                if (this.parent) {
                    const p = new Point(this.parent);
                    p.href = Utils.buildSref(p, LanguageService.getCurrentLanguage());
                    this.parent = p;
                }
            },
            getId() {
                return this.id;
            },
            getCategory() {
                return this.category && this.category.title ? this.category.title : null;
            },
            getParent() {
                return this.parent ? this.parent : null;
            },
            getCoverImage() {
                if (!this.gallery || !this.gallery.length) {
                    return null;
                }

                const image = this.gallery.filter((img) => {
                    return img.isCoverImage === true;
                });

                if (!image.length) {
                    return this.gallery[0].file;
                }

                return image[0].file;
            },
            getGeoJSON() {
                if (this.location == null) {
                    return null;
                }

                const obj = {
                    type: 'Feature',
                    geometry: angular.copy(this.location),
                    properties: {
                        pointId: this.id,
                        title: this.title
                    }
                };

                return obj;
            }
        };

        return Point;
    }
})();
