(function () {
    angular.module('alterWebApp').directive('menuDirective', ($rootScope, $state, $http, $window, $stateParams, $timeout, LanguageService, Utils) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {},
            templateUrl: 'app/shared/menu/template.html',
            link: (scope, element, attrs) => {
                scope.languages = $rootScope.languages;
                scope.currentLanguage = $rootScope.currentLanguage;
                scope.menuItems = [];
                scope.search = {
                    input: null,
                    text: '',
                    isActive: false,
                    minlength: 3
                };

                scope.isTransparent = false;
                scope.showMobileMenu = false;

                const logoVariations = {
                    light: 'assets/img/alter-do-chao_high_original_light.png',
                    dark: 'assets/img/alter-do-chao_high_original.png'
                };
 
                $rootScope.$on('$stateChangeStart', init);
                init();

                scope.onGoTo = (ev, route) => {
                    ev.preventDefault();

                    if (!route || route === '') {
                        return;
                    }

                    $state.go(route, { lang: scope.currentLanguage.getCode() });
                };

                scope.onLanguageChange = (ev, isoCode) => {
                    ev.preventDefault();
                    LanguageService.updateLangParam(isoCode, () => {
                      //  $window.location.reload();
                    });
                };

                scope.onSearchClick = (ev) => {
                    ev.preventDefault();
                    if (!$rootScope.isMobile) {
                        if (!scope.search.isActive) {
                            if (!scope.search.input) {
                                scope.search.input = document.getElementById('searchBarInput');
                            }

                            scope.search.input.focus();
                        }

                        scope.search.isActive = !scope.search.isActive;
                    }
                    else {
                        $state.go('app.search', { search: '' });
                    }
                };

                scope.onSearchKeyUp = (ev) => {
                    ev.preventDefault();
                    if (scope.search.text && scope.search.text.trim() !== '') {
                        if ((ev.code === 'Enter' || ev.code === 'NumpadEnter') && scope.search.text.trim().length >= 3) {
                            $state.go('app.search', { search: encodeURI(scope.search.text) });
                        }
                    }
                };

                scope.onSearch = (ev) => {
                    ev.preventDefault();
                    $state.go('app.search', { search: encodeURI(scope.search.text) });
                };

                scope.mobileBurguerClick = (ev) => {
                    ev.preventDefault();
                    scope.showMobileMenu = !scope.showMobileMenu;
                };

                function bindEvents() {
                    angular.element($window).on('scroll', () => {
                        if ($state.current.name === 'app.home') {
                            const mainNav = document.getElementById('mainNav');
                            const scrollTop = $window.scrollY;

                            if (!mainNav) {
                                return;
                            }

                            if (scope.isTransparent && (scrollTop > mainNav.clientHeight)) {
                                scope.isTransparent = false;
                                scope.logoSrc = logoVariations.dark;
                                scope.$apply();
                            }
                            else if (!scope.isTransparent && (scrollTop <= mainNav.clientHeight)) {
                                scope.isTransparent = true;
                                scope.logoSrc = logoVariations.light;
                                scope.$apply();
                            }
                        }
                    });
                }

                function init(event, toState) {
                    const homeState = 'app.home';
                    scope.search.isActive = false;
                    scope.showMobileMenu = false;

                    if ((toState && toState.name === homeState) || (!toState && $state.current.name === homeState)) {
                        scope.isTransparent = true;
                        scope.logoSrc = logoVariations.light;
                    }
                    else {
                        scope.isTransparent = false;
                        scope.logoSrc = logoVariations.dark;
                    }
 
                    getMenuItems();
                    bindEvents();

                }

                function getMenuItems() {
                    scope.menuItems = [];
                    $http.get('app/menus/menus.' + scope.currentLanguage.getCode() + '.json').then((response) => {
                        const menus = response.data;

                        if (menus && menus.length) {
                            scope.menuItems = menus.map((menu) => {
                                menu.route = Utils.buildLangSref(menu.route, scope.currentLanguage.getCode());
                                return menu;
                            });
                        }
                    });
                }
            }
        };
    });
})();
