(function () {
    angular.module('alterWebApp').directive('newsletterDirective', ($rootScope, GeneralConst, NewsletterService) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: { },
            templateUrl: 'app/shared/newsletter/template.html',
            link: (scope, element, attrs) => {
                scope.requestInProgress = false;
                scope.currentEmail = '';
                scope.inputState = {
                    success: false,
                    error: false,
                    message: ''
                };

                scope.onSubscribe = () => {
                    if (!scope.currentEmail || scope.requestInProgress) {
                        return;
                    }

                    const reqBody = {
                        email: scope.currentEmail
                    };

                    scope.requestInProgress = true;
                    NewsletterService.subscribe(reqBody)
                        .then(() => {
                            scope.currentEmail = '';
                            scope.inputState.success = true;
                            scope.inputState.message = $rootScope.stringsCollection.newsletterSucessText;

                            scope.requestInProgress = false;

                            setTimeout(() => {
                                scope.inputState.success = false;
                            }, 200);
                        })
                        .catch((err) => {
                            scope.requestInProgress = false;
                            scope.inputState.error = true;
                            scope.inputState.message = $rootScope.stringsCollection.defaultEmailError;

                            if (err.data.systemCode === GeneralConst.EMAIL_ALREADY_EXISTS) {
                                scope.inputState.message = $rootScope.stringsCollection.emailAlreadyExists;
                                console.log('Bad Request => ', err.data.message);
                            }
                        });
                };

                scope.hideError = ($ev) => {
                    if (scope.currentEmail !== '' && $ev.code !== 'Enter' && $ev.code !== 'NumpadEnter') {
                        scope.inputState.error = false;
                    }
                };
            }
        };
    });
})();
