(function () {
    angular.module('alterWebApp').factory('News', NewsModel);

    function NewsModel(Utils, $rootScope, LanguageService) {
        function News(data) {
            if (data) {
                this.setData(data);
            }
        }

        News.prototype = {
            setData(data) {
                this.id = data._id;
                this.slug = Utils.initValue(data.slug);
                this.title = Utils.initValue(data.title);
                this.category = Utils.initValue(data.category);
                this.parent = Utils.initValue(data.relatedPoint);
                this.date = Utils.initValue(data.date);
                this.author = Utils.initValue(data.author);
                this.excerpt = Utils.initValue(data.excerpt) ? Utils.initValue(data.excerpt) : this.description;
                this.description = Utils.initValue(data.description);
                this.gallery = Utils.initValue(data.gallery);
                this.state = 'app.newsProfile';

                this.category = this.getCategory();
                this.coverImage = Utils.initValue(data.coverImage) ? data.coverImage.file : this.getCover();

                if (this.parent) {
                    const p = new News(this.parent);
                    p.href = Utils.buildSref(p, LanguageService.getCurrentLanguage());
                    this.parent = p;
                }
            },
            getId() {
                return this.id;
            },
            getCategory() {
                return this.category && this.category.title ? this.category.title : null;
            },
            getCover() {
                if (!this.gallery || !this.gallery.length) {
                    return null;
                }

                const image = this.gallery.filter((img) => {
                    return img.isCoverImage === true;
                });

                if (!image.length) {
                    return this.gallery[0].file;
                }

                return image[0].file;
            }
        };

        return News;
    }
})();
