(function () {
    angular.module('alterWebApp').service('LanguageService', LanguageService);

    function LanguageService(ApiService, $rootScope, $state, StorageService, Language, $stateParams, $window, $timeout) {
        const baseUrl = '/api/v1';

        this.init = function (callback) {
            this.getLanguages()
                .then((data) => {
                    let defaultLang = null;
                    const systemLanguages = data.data.data.map((sl) => {
                        const lang = new Language(sl);

                        if (lang && lang.isDefault()) {
                            defaultLang = lang;
                        }

                        return lang;
                    });

                    if (systemLanguages.length === 0) {
                        return new Error('No Languages provided');
                    }

                    $rootScope.languages = systemLanguages;

                    const langParam = $stateParams.lang;
                    const langStorage = StorageService.getClientLanguage();

                    if (!langParam && !langStorage) {
                        $rootScope.currentLanguage = defaultLang;
                    }
                    else if (!langParam && langStorage) {
                        if (langStorage !== defaultLang.getCode()) {
                            if (this.languageExists(langStorage)) {
                                const ls = systemLanguages.find(sl => sl.getCode() === langStorage);
                                this.updateLangParam(langStorage);
                                $rootScope.currentLanguage = ls;
                            }
                            else {
                                $rootScope.currentLanguage = defaultLang;
                            }
                        }
                        else {
                            $rootScope.currentLanguage = defaultLang;
                        }
                    }
                    else if (langParam && langStorage && langParam !== langStorage) {
                        this.updateLangParam(langParam);
                        const ls = systemLanguages.find(sl => sl.getCode() === langParam);
                        $rootScope.currentLanguage = ls;
                    }
                    else {
                        if (langParam && this.languageExists(langParam)) {
                            this.updateLangParam(langParam);
                            const ls = systemLanguages.find(sl => sl.getCode() === langParam);
                            $rootScope.currentLanguage = ls;
                        }
                        else {
                            this.updateLangParam(defaultLang.getCode());
                            $rootScope.currentLanguage = defaultLang;
                        }
                    }

                    this.setCurrentLanguage($rootScope.currentLanguage.getCode());
                    return callback();
                })
                .catch((error) => {
                    return callback(error);
                });
        };

        this.getLanguages = function () {
            return ApiService.getPublic(baseUrl + '/languages');
        };

        this.getCurrentLanguage = function () {
            return StorageService.getClientLanguage();
        };

        this.setCurrentLanguage = function (isoCode) {
            return StorageService.setClientLanguage(isoCode);
        };

        this.languageExists = (isoCode) => {
            const languages = $rootScope.languages;
            return languages.filter(l => l.isoCode === isoCode).length > 0;
        };

        this.getDefaultLanguage = () => {
            const languages = $rootScope.languages;
            return languages.find(l => l.default === true);
        };

        this.updateLangParam = (isoCode, callback) => {
            $state.go($state.current, { lang: isoCode }, { reload: false }).then(() => {
                if (callback) {
                    callback();
                }
            });
        };
    }
})();
