(function () {
    angular.module('alterWebApp').controller('SinglePointController', SinglePointController);

    function SinglePointController($scope, $q, $rootScope, $state, $filter, Utils, Point, PointsService, FileService, $stateParams) {
        const self = this;
        self.isReady = false;
        self.point = null;
        self.images = [];
        self.galleryRatio = ($rootScope.isMobile ? '3:2' : '3:1');

        self.initView = () => {
            if (angular.isUndefined($stateParams.slug)) {
                $state.go('home');
            }

            PointsService.get($stateParams.slug)
                .then((data) => {
                    const point = new Point(data.data.data);
                    self.point = point;

                    if (point.slug !== $state.params.slug) {
                        $state.go('app.pointProfile', { lang: $rootScope.currentLanguage.getCode(), slug: point.slug }, { reload: false });
                    }

                    if (typeof point.gallery !== 'undefined' && point.gallery.length) {
                        self.images = self.buildGallery(point.gallery);
                    }
                })
                .catch((error) => {
                    console.log('error getting point', error);
                });
        };

        self.buildGallery = (images) => {
            if (!images.length) {
                return [];
            }

            const items = images.map((img) => {
                img.file = FileService.getLandscape(img.file);
                return img;
            });

            return $filter('orderBy')(items, ['-isPanorama', 'order']);
        };
    }
})();
