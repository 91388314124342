(function () {
    angular.module('alterWebApp').config(appRoutes);

    function appRoutes($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise(($injector) => {
            const $stateParams = $injector.get('$stateParams');
            return '/' + $stateParams.lang + '/404';
        });

        $stateProvider
            .state('app', {
                abstract: true,
                url: '/{lang:(?:pt|en)}',
                params: {
                    lang: {
                        squash: true,
                        value: null
                    }
                },
                templateUrl: 'app/sections/root/template.html',
                controller: 'AppController',
                controllerAs: 'appCtrl'
            })
            .state('app.home', {
                url: '',
                templateUrl: 'app/sections/home/template.html',
                controller: 'HomeController',
                controllerAs: 'homeCtrl'
            })
            .state('app.notFound', {
                url: '/404',
                templateUrl: 'app/sections/default/template.html',
                controller: 'NotFoundController',
                controllerAs: 'notFoundCtrl'
            })
            .state('app.nucleus', {
                url: '/nucleos-museologicos',
                templateUrl: 'app/sections/nucleus/nucleusList.html',
                controller: 'NucleusController',
                controllerAs: 'nucleusCtrl'
            })
            .state('app.nucleusMain', {
                abstract: true,
                url: '/nucleos-museologicos/:slug',
                templateUrl: 'app/sections/nucleus/nucleusMain.html',
                controller: 'MainNucleusController',
                controllerAs: 'mainNucleusCtrl'
            })
            .state('app.nucleusMain.nucleusVisit', {
                url: '',
                templateUrl: 'app/sections/nucleus/nucleusVisit.html',
                controller: 'VisitNucleusController',
                controllerAs: 'visitNucleusCtrl'
            })
            .state('app.nucleusMain.nucleusNews', {
                url: '/noticias',
                templateUrl: 'app/sections/news/newsView.html',
                controller: 'NewsController',
                controllerAs: 'newsCtrl'
            })
            .state('app.nucleusMain.nucleusEvents', {
                url: '/agenda',
                templateUrl: 'app/sections/events/eventsView.html',
                controller: 'EventsController',
                controllerAs: 'eventsCtrl'
            })
            .state('app.nucleusMain.nucleusContacts', {
                url: '/contactos',
                templateUrl: 'app/sections/nucleus/nucleusContacts.html',
                controller: 'contactsNucleusController',
                controllerAs: 'contactsNucleusCtrl'
            })
            .state('app.nucleusMain.nucleusPricing', {
                url: '/precario',
                templateUrl: 'app/sections/nucleus/nucleusPricing.html',
                controller: 'pricingNucleusController',
                controllerAs: 'pricingNucleusCtrl'
            })
            .state('app.news', {
                url: '/noticias',
                templateUrl: 'app/sections/news/newsView.html',
                controller: 'NewsController',
                controllerAs: 'newsCtrl'
            })
            .state('app.newsProfile', {
                url: '/noticias/:slug',
                templateUrl: 'app/sections/news/singleView.html',
                controller: 'SingleNewsController',
                controllerAs: 'singleNewsCtrl'
            })
            .state('app.events', {
                url: '/agenda',
                templateUrl: 'app/sections/events/eventsView.html',
                controller: 'EventsController',
                controllerAs: 'eventsCtrl'
            })
            .state('app.eventProfile', {
                url: '/agenda/:slug',
                templateUrl: 'app/sections/events/singleView.html',
                controller: 'SingleEventController',
                controllerAs: 'singleEventCtrl'
            })
            .state('app.points', {
                url: '/pontos-de-interesse',
                templateUrl: 'app/sections/points/pointsView.html',
                controller: 'PointsController',
                controllerAs: 'pointsCtrl'
            })
            .state('app.pointProfile', {
                url: '/pontos-de-interesse/:slug',
                templateUrl: 'app/sections/points/singleView.html',
                controller: 'SinglePointController',
                controllerAs: 'singlePointCtrl'
            })
            .state('app.map', {
                url: '/mapa',
                templateUrl: 'app/sections/map/template.html',
                controller: 'MapController',
                controllerAs: 'mapCtrl'
            })
            .state('app.services', {
                abstract: true,
                url: '/servicos/:slug',
                templateUrl: 'app/sections/services/template.html',
                controller: 'ServicesController',
                controllerAs: 'servicesCtrl'
            })
            .state('app.services.servicesCategory', {
                url: '',
                templateUrl: 'app/sections/services/list.html',
                controller: 'ServicesListController',
                controllerAs: 'servicesListCtrl'
            })
            .state('app.search', {
                url: '/pesquisa/{type}?search',
                params: {
                    type: {
                        squash: true,
                        value: null
                    }
                },
                templateUrl: 'app/sections/search/template.html',
                controller: 'SearchController',
                controllerAs: 'searchCtrl'
            })
            .state('app.unsubscribe', {
                url: '/newsletter/unsubscribe/:token',
                templateUrl: 'app/sections/newsletter/template.html',
                controller: 'newsletterController',
                controllerAs: 'newsletterCtrl'
            });
    }
})();
