(function () {
    angular.module('alterWebApp').directive('footerDirective', ($rootScope, $state, FileService) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {},
            templateUrl: 'app/shared/footer/template.html',
            link: (scope, element, attrs) => {
                scope.currentYear = (new Date()).getFullYear() + ' ';
                scope.contacts = $rootScope.contacts;

                scope.logos = $rootScope.footerLogos.map((l) => {
                    l.src = FileService.getUrl(l.file);
                    return l;
                });
            }
        };
    });
})();
