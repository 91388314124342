(function () {
    angular.module('alterWebApp').controller('pricingNucleusController', PricingNucleusController);

    function PricingNucleusController($scope, $state, $rootScope, $stateParams, Utils, LanguageService) {
        const self = this;
        self.isReady = false;

        self.initView = () => {
            $scope.point = $scope.$parent.point;
            self.isReady = true;
        };
    }
})();
