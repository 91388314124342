(function () {
    angular.module('video-directive', []).directive('videoDirective', ($window, $rootScope, $timeout, FileService, GeneralConst, Utils) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                video: '='
            },
            templateUrl: 'app/components/video/template.html',
            link: (scope, element, attrs) => {
                const videoEl = element[0].querySelector('video');
                let pausedByScroll = false;

                scope.isReady = false;
                scope.isPlaying = false;
                scope.muted = false;
                scope.autoplay = false;
                scope.loop = false;
                scope.controls = false;
                scope.src = null;
                scope.type = null;
                scope.fit = false;
                scope.sticky = false;
                scope.placeholder = FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.LANDSCAPE);
                scope.containerHeight = '100%'; 
                scope.height = '100%';
                scope.width = '100%';
                scope.pipWindow = null;

                scope.$watch('video', init, true);

                if (!scope.video.src) {
                    scope.isReady = true;
                }

                scope.togglePlay = (e) => {
                    if (scope.isPlaying) {
                        videoEl.pause();
                    }
                    else {
                        videoEl.play();

                        if (scope.sticky && !$rootScope.isMobile) {
                            if (typeof videoEl.requestPictureInPicture === 'function') {
                                videoEl.requestPictureInPicture()
                                    .then(() => {
                                        bindPipEvents();
                                    })
                                    .catch((error) => {
                                        console.log('error pip', error);
                                    });
                            }
                            else if (videoEl.webkitSupportsPresentationMode && typeof videoEl.webkitSetPresentationMode === 'function') {
                                try {
                                    videoEl.webkitSetPresentationMode(videoEl.webkitPresentationMode === 'picture-in-picture'
                                        ? 'inline'
                                        : 'picture-in-picture');

                                    bindPipEvents();
                                }
                                catch (err) {
                                    console.log('webkitSetPresentationMode error', err);
                                }
                            }

                            bindPipEvents();
                        }
                    }
                };

                function init() {
                    if (!scope.video) {
                        return;
                    }

                    if (typeof scope.video === 'object') {
                        if (typeof scope.video.placeholder !== 'undefined') {
                            scope.placeholder = scope.video.placeholder;
                        }

                        if (typeof scope.video.src === 'object') {
                            let video;

                            /* eslint-disable */
                            for (var k in scope.video.src){
                                if (scope.video.src.hasOwnProperty(k)) {
                                    const type = scope.video.src[k].split('.').pop();
                                    const formats = {
                                        webm: 'video/webm',
                                        ogg: 'video/ogg',
                                        mp4: 'video/mp4'
                                    };

                                    if (!video) {
                                        video = document.createElement('video');
                                    }

                                    if (typeof formats[type] !== 'undefined' && video.canPlayType(formats[type])) {
                                        scope.src = scope.video.src[k];
                                        break
                                    }
                                }
                            }
                            /* eslint-enable */
                        }
                        else {
                            if (scope.video.src.includes('.')) {
                                scope.type = `video/${scope.video.src.split('.').pop()}`;
                            }
                            else {
                                scope.type = 'video/mp4';
                            }

                            scope.src = scope.video.src;
                        }

                        if (!scope.src) {
                            return;
                        }

                        if (typeof scope.video.muted !== 'undefined') {
                            scope.muted = scope.video.muted;
                        }

                        if (typeof scope.video.autoplay !== 'undefined') {
                            scope.autoplay = scope.video.autoplay;
                        }

                        if (typeof scope.video.loop !== 'undefined') {
                            scope.loop = scope.video.loop;
                        }

                        if (typeof scope.video.controls !== 'undefined') {
                            scope.controls = scope.video.controls;
                        }

                        if (typeof scope.video.height !== 'undefined') {
                            scope.height = scope.video.height;
                        }

                        if (typeof scope.video.width !== 'undefined') {
                            scope.width = scope.video.width;
                        }

                        if (typeof scope.video.fit !== 'undefined') {
                            scope.fit = scope.video.fit;
                        }

                        if (typeof scope.video.sticky !== 'undefined') {
                            scope.sticky = scope.video.sticky;
                        }
                    }
                    else {
                        scope.type = `${scope.video.split('.').pop()}`;
                        scope.src = scope.video;
                    }

                    if (scope.src) {
                        scope.src = FileService.getUrl(scope.src);
                        scope.videoScrollListener = $window.addEventListener('scroll', toggleVideoEvents);
    
    
                        videoEl.addEventListener('pause', () => {
                            scope.isPlaying = false;
                            scope.$apply();
                           // videoEl.removeAttribute('controls');
                        });
    
                        videoEl.addEventListener('end', () => {
                            scope.isPlaying = false;
                            scope.$apply();
                         //   videoEl.removeAttribute('controls');
                        });
    
                        videoEl.addEventListener('playing', () => {
                            scope.isPlaying = true;
                            scope.$apply();
                          //  videoEl.setAttribute('controls', 'controls');
                        });                            
                    }
                    else {
                        scope.isReady = true;
                        return;
                    }

                    if (!scope.fit) {
                        scope.isReady = true;
                        return;
                    }

                    scope.videoResizeListener = $window.addEventListener('resize', setVideoDimensions(() => {
                        if (!scope.isReady) {
                            scope.isReady = true;
                        }
                    }));

                    $timeout(() => {
                        $window.dispatchEvent(new Event('resize'));
                    });

                    scope.$on('$destroy', () => {
                        window.removeEventListener('resize', setVideoDimensions());

                        if (typeof scope.videoScrollListener !== 'undefined') {
                            window.removeEventListener('scroll', toggleVideoEvents);
                        }
                    });

                    if (scope.autoplay && scope.src) {
                        videoEl.play();
                    }
                }

                function bindPipEvents() {
                    videoEl.addEventListener('enterpictureinpicture', (event) => {
                        scope.pipWindow = event.pictureInPictureWindow;
                    });

                    videoEl.addEventListener('leavepictureinpicture', () => {
                        scope.pipWindow = null;
                    });
                }

                function toggleVideoEvents() {
                    const container = element[0].querySelector('.video-banner');
                    const initial = container.getBoundingClientRect();

                    if (!scope.sticky) {
                        if (scope.isPlaying && !Utils.onScreen(videoEl)) {
                            videoEl.pause();
                            pausedByScroll = true;
                        }
                        else if (Utils.onScreen(videoEl) && pausedByScroll) {
                            videoEl.play();
                            pausedByScroll = false;
                        }
                    }
                }

                function setVideoDimensions(callback) {
                    const navH = document.getElementById('mainNav').clientHeight;
                    const container = element[0].querySelector('.video-banner');
                    container.style.height = $window.innerHeight - navH + 'px';

                    if (callback) {
                        callback();
                    }
                }
            }
        };
    });
})();
