(function () {
    angular.module('alterWebApp').service('StorageService', StorageService);

    function StorageService($window, $rootScope, StorageConst, GeneralConst) {
        this.getClientLang = function () {
            if (this.getClientLanguage()) {
                return this.getClientLanguage();
            }

            return GeneralConst.DEFAULT_LANG;
        };

        this.setClientLanguage = function (isoCode) {
            return $window.localStorage.setItem(StorageConst.LANGUAGE_KEY, isoCode);
        };

        this.getClientLanguage = function () {
            return $window.localStorage.getItem(StorageConst.LANGUAGE_KEY);
        };
    }
})();
