(function () {
  angular
    .module("alterWebApp")
    .controller("ServicesListController", ServicesListController);

  function ServicesListController(
    $scope,
    $q,
    $state,
    $rootScope,
    $stateParams,
    $window,
    ServicesService,
    Service,
    Utils
  ) {
    const self = this;
    $scope.currentCategory = 0;
    $scope.loading = false;
    $scope.listReady = false;
    self.eventsListEl = null;
    self.services = [];
    self.nextPage = false;

    self.searchObj = {
      page: 1,
      limit: 6,
      category: null,
    };

    $scope.$on("$stateChangeStart", filterServices);
    filterServices();

    function filterServices(reset = true) {
      $scope.loading = true;
      self.searchObj.category = $scope.$parent.currentCategory.id;

      if (self.searchObj.category === 0) {
        self.searchObj.category = undefined;
      }

      if (reset) {
        self.services = [];
        self.searchObj.page = 1;
      }

      ServicesService.search(self.searchObj)
        .then((data) => {
          const res = data.data.data;

          if (res.total) {
            res.docs.forEach((ne) => {
              const obj = new Service(ne);
              self.services.push(obj);
            });
          }

          self.searchObj.page += 1;
          self.nextPage = self.searchObj.page <= res.pages;
          $scope.loading = false;

          if (!$scope.listReady) {
            self.eventsListEl = document.getElementById("servicesList");
            bindEvents();
            $scope.listReady = true;
          }
        })
        .catch((error) => {
          $scope.loading = false;
        });
    }

    function paginate() {
      if (!self.nextPage) {
        return;
      }

      filterServices(false);
    }

    function bindEvents() {
      angular.element($window).on("scroll", (e) => {
        if (!$scope.loading && Utils.closeToBottom(self.eventsListEl)) {
          paginate();
        }
      });
    }
  }
})();
