(function () {
    angular.module('alterWebApp').controller('SearchController', SearchController);

    function SearchController($scope, $q, $state, $rootScope, $stateParams, $timeout, SearchService, Point, Event, News, Utils) {
        const self = this;
        $scope.currentEmail = '';
        $scope.noResults = false;
        $scope.loading = false;
        $scope.isReady = false;

        $scope.inputState = {
            success: false,
            error: false,
            message: ''
        };

        $scope.search = {
            text: '',
            input: null,
            type: undefined,
            minlength: 3,
            results: {
                news: [],
                points: [],
                events: []
            },
            pagination: {
                limit: 4,
                current: 1,
                total: 0,
                next: 2
            },
            paginationTemplate: 'bower_components/angularUtils-pagination/dirPagination.tpl.html'
        };

        $scope.filters = {
            news: {
                value: 'NEWS',
                title: $rootScope.stringsCollection.newsFilterLabel,
                href: 'noticias',
                total: 0,
                docs: []
            },
            points: {
                value: 'POI',
                title: $rootScope.stringsCollection.pointsOfInterestFilterLabel,
                href: 'pontos-de-interesse',
                total: 0,
                docs: []
            },
            events: {
                value: 'AGENDA',
                title: $rootScope.stringsCollection.eventsFilterLabel,
                href: 'agenda',
                total: 0,
                docs: []
            }
        };

        self.initView = function () {
            $scope.loading = true;
            $scope.search.text = $stateParams.search ? decodeURI($stateParams.search) : '';
            if ($stateParams.type) {
                $scope.search.pagination.limit = 8;
                const k = Object.keys($scope.filters).find(key => $scope.filters[key].href === $stateParams.type);

                if (typeof k !== 'undefined') {
                    $scope.search.type = $scope.filters[k].value.toUpperCase();
                }

                if ($scope.search.type) {
                    $scope.search.type = $scope.search.type.toUpperCase();
                }
            }
            else {
                $scope.search.pagination.limit = 8;
            }

            if ($scope.search.text && $scope.search.text.trim().length >= $scope.search.minlength) {
                $scope.filterResults();
            }
            else {
                $scope.noResults = true;
                $scope.loading = false;
                $scope.isReady = true;
                focusInput();
            }
        };

        $scope.toggleFilter = (ev, type) => {
            ev.preventDefault();
            if (!ev.target.classList.contains('active')) {
                $state.go('app.search', { type, search: $scope.search.text }, { reload: true });
            }
            else {
                $state.go('app.search', { type: null, search: $scope.search.text }, { reload: true });
            }
        };

        $scope.onSearchClick = (ev) => {
            ev.preventDefault();
            if ($scope.search.text && $scope.search.text.trim().length >= 3) {
                $state.go('app.search', { search: encodeURI($scope.search.text) });
            }
            else {
                $scope.loading = false;
                $scope.isReady = true;
                $scope.inputState.error = true;
                $scope.inputState.message = $rootScope.stringsCollection.charactersLengthWarningLabel;
            }
        };

        $scope.searchKeyUp = ($ev) => {
            if ($scope.search.text && $scope.search.text.trim() !== '') {
                $scope.inputState.error = false;

                if ($ev.code === 'Enter' || $ev.code === 'NumpadEnter') {
                    if ($scope.search.text && $scope.search.text.trim().length >= 3) {
                        $state.go('app.search', { search: encodeURI($scope.search.text) });
                    }
                }
            }
        };

        $scope.filterResults = () => {
            const args = {
                text: $scope.search.text,
                type: $scope.search.type,
                limit: $scope.search.pagination.limit,
                page: $scope.search.pagination.current
            };
            console.log(args);
            SearchService.get(args)
                .then((data) => {
                    const res = data.data.data;
                    $scope.noResults = true;
                    if (typeof res.news !== 'undefined' && res.news.docs.length > 0) {
                        $scope.noResults = false;
                        $scope.filters.news.docs = res.news.docs.map((n) => {
                            const newsObj = new News(n);
                            newsObj.href = Utils.buildSref(newsObj);
                            return newsObj;
                        });

                        if ($scope.search.type) {
                            $scope.search.pagination.total = res.total.news;
                            $scope.search.pagination.current = res.news.page;
                            if ($scope.search.pagination.page < res.news.pages) {
                                $scope.search.pagination.next += 1;
                            }
                        }
                    }
                    else {
                        $scope.filters.news.total = 0;
                        $scope.filters.news.docs = [];
                    }

                    if (typeof res.points !== 'undefined' && res.points.docs.length > 0) {
                        $scope.noResults = false;
                        $scope.filters.points.docs = res.points.docs.map((p) => {
                            const pointObj = new Point(p);
                            pointObj.href = Utils.buildSref(pointObj);
                            return pointObj;
                        });

                        if ($scope.search.type) {
                            $scope.search.pagination.total = res.total.points;
                            $scope.search.pagination.current = res.points.page;
                            if ($scope.search.pagination.page < res.points.pages) {
                                $scope.search.pagination.next += 1;
                            }
                        }
                    }

                    if (typeof res.agenda !== 'undefined' && res.agenda.docs.length > 0) {
                        $scope.noResults = false;
                        $scope.filters.events.docs = res.agenda.docs.map((e) => {
                            const ev = new Event(e);
                            ev.href = Utils.buildSref(ev);
                            return ev;
                        });

                        if ($scope.search.type) {
                            $scope.search.pagination.total = res.total.agenda;
                            $scope.search.pagination.current = res.agenda.page;
                            if ($scope.search.pagination.page < res.agenda.pages) {
                                $scope.search.pagination.next += 1;
                            }
                        }
                    }

                    if (typeof res.total !== 'undefined') {
                        $scope.filters.points.total = res.total.points;
                        $scope.filters.events.total = res.total.agenda;
                        $scope.filters.news.total = res.total.news;
                    }
                    else {
                        $scope.filters.points.total = 0;
                        $scope.filters.events.total = 0;
                        $scope.filters.news.total = 0;
                    }

                    $scope.loading = false;
                    $scope.isReady = true;
                    focusInput();
                })
                .catch((error) => {
                    ;
                    $scope.noResults = true;
                    $scope.loading = false;
                    $scope.isReady = true;
                    focusInput();
                });
        };

        function focusInput() {
            if (!$scope.search.input) {
                $scope.search.input = document.getElementById('search');
            }

            $timeout(() => {
                if ($scope.search.input) {
                    $scope.search.input.focus();
                }
            });
        }
    }
})();
