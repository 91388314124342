(function () {
    angular.module('alterWebApp').controller('newsletterController', NewsletterController);

    function NewsletterController($scope, $q, $rootScope, $state, $stateParams, NewsletterService) {
        const self = this;
        $scope.isReady = false;
        $scope.newsletter = {
            success: false
        };

        self.initView = () => {
            if (angular.isUndefined($stateParams.token)) {
                $state.go('home');
            }

            NewsletterService.unsubscribe($stateParams.token)
                .then((data) => {
                    if (data.data.success) {
                        $scope.newsletter.success = true;
                        return;
                    }

                    $scope.newsletter.success = false;
                })
                .catch((error) => {
                    $scope.newsletter.success = false;
                });

            $scope.isReady = true;
        };
    }
})();
