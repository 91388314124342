(function () {
  angular
    .module("alterWebApp")
    .controller("PointsController", PointsController);

  function PointsController(
    $scope,
    $q,
    $rootScope,
    $state,
    $timeout,
    LanguageService,
    Utils,
    Point,
    PointsService
  ) {
    const self = this;
    self.related = [];
    self.pointsTree = [];
    self.content = null;

    self.initView = function () {
      const level = 1;

      if ($rootScope.mainInfo) {
        self.content = {
          title: $rootScope.mainInfo.title,
          description: $rootScope.mainInfo.description,
          video: {
            src: $rootScope.mainInfo.file,
            autoplay: true,
            loop: false,
            muted: true,
            fit: true,
          },
        };
      }

      PointsService.getPointsTree({ level })
        .then((data) => {
          for (let i = 0; i < data.data.data.length; i += 1) {
            const n = new Point(data.data.data[i]);
            n.href = Utils.buildSref(n, LanguageService.getCurrentLanguage());
            self.pointsTree.push(n);
          }
        })
        .catch((error) => {});
    };
  }
})();
