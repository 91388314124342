(function () {
    angular.module('alterWebApp').directive('socialNetworkDirective', (FileService) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                items: '='
            },
            templateUrl: 'app/shared/social-network/template.html',
            link: (scope, element, attrs) => {
                scope.socialNetworks = scope.items.map((s) => {
                    return {
                        name: s.name,
                        link: s.link,
                        icon: FileService.getUrl(s.icon)
                    };
                });
            }
        };
    });
})();
