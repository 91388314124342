(function () {
    angular.module('alterWebApp').controller('NucleusController', NucleusController);

    function NucleusController($scope, $timeout, $rootScope, $window, Point, PointsService, Utils, GeneralConst, FileService, LanguageService) {
        const self = this;
        self.nucleus = [];
        self.isReady = false;
        $scope.columnHeight = 0;

        self.initView = function () {
            PointsService.getMuseums()
                .then((data) => {
                    if (!data.data.data.length) {
                        return;
                    }

                    self.nucleus = data.data.data.map((n) => {
                        const p = new Point(n);
                        p.href = Utils.buildSref(p, LanguageService.getCurrentLanguage());
                        return p;
                    });

                    $window.addEventListener('resize', setcolumnHeight);
                    imagesLoaded(document.getElementsByTagName('img'), () => {
                        self.isReady = true;
                        $window.dispatchEvent(new Event('resize'));
                    });
                })
                .catch((error) => {
                    ;
                });
        };

        $scope.getVerticalCoverSrc = (imageId) => {
            if (!imageId) {
                return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.LANDSCAPE);
            }

            return FileService.getLandscape(imageId);
        };

        $scope.getIconSrc = (icon) => {
            return FileService.getUrl(icon);
        };

        $scope.$on('$destroy', () => {
            $window.removeEventListener('resize', setcolumnHeight);
        });

        function setcolumnHeight() {
            $timeout(() => {
                const navH = document.getElementById('mainNav').clientHeight;
                const height = $rootScope.isMobile ? (window.innerHeight - navH) / 3 : window.innerHeight - navH;
                $scope.columnHeight = height;
            });
        }
    }
})();
