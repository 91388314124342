(function () {
    angular.module('alterWebApp').directive('socialShare', ($rootScope, $state, $window, FileService, GeneralConst, Utils, $timeout) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                data: '='
            },
            templateUrl: 'app/shared/share-list/template.html',
            link: (scope, element, attrs) => {
                
                scope.socialShareList = [
                    {
                        codename: 'Twitter',
                        icon: 'icon-twitter',
                        label: $rootScope.stringsCollection.twitterShareLabel
                    },
                    {
                        codename: 'Facebook',
                        icon: 'icon-facebook',
                        label: $rootScope.stringsCollection.facebookShareLabel
                    }
                ];

                scope.$watch('data', () => {
                    $timeout(() => {
                        const tips = element[0].getElementsByClassName('share-btn');
                        if (tips.length) {
                            for (let i = 0; i < tips.length; i += 1) {
                                const content = tips[i].getAttribute('data-tippy-content');

                                if (content) {
                                    tippy(tips[i], { content });
                                    const currentTip = tips[i]._tippy;
                                    const clickContent = tips[i].getAttribute('data-tippy-click-content');
                                    if (clickContent) {
                                        tips[i].addEventListener('click', () => {
                                            currentTip.setContent(clickContent);
                                            setTimeout(() => {
                                                currentTip.show();
                                                setTimeout(() => {
                                                    currentTip.setContent(content);
                                                }, 3500);
                                            }, 10);
                                        });
                                    }
                                }
                            }
                        }
                    });
                })

                scope.onShareCard = (ev, item) => {
                    ev.stopImmediatePropagation();
                    const protocol = window.location.protocol + '//';
                    const host = window.location.host;
                    const path = encodeURIComponent(protocol + host + '/' + Utils.getRoute($state, scope.data, $rootScope.currentLanguage.getCode()));

                    
                    switch (item.codename) {
                        case 'Facebook':
                            if (typeof FB !== 'undefined') {
                                FB.ui({
                                    method: 'share',
                                    mobile_iframe: true,
                                    href: path,
                                }); 
                            }
                            break;

                        case 'Twitter':
                            const text =  encodeURI(scope.data.title + '\n\n' + scope.data.description + '\n\n');
                            $window.open('https://twitter.com/intent/tweet?text=' + text + '&url=' + path, '_blank', 'height=500,width=320');
                            break;
                        default:
                            break;
                    }
                };

                scope.onCopyClick = ($ev) => {
                    $ev.preventDefault();
                    const path = Utils.getRoute($state, scope.data, $rootScope.currentLanguage.getCode());
                    Utils.copyToClipboard(path);
                };
            }
        }
    });
})();
