(function () {
    angular.module('alterWebApp').service('EventsService', EventsService);

    function EventsService(ApiService) {
        const baseUrl = '/api/v1/agenda';

        this.get = function (slug) {
            return ApiService.getPublic(baseUrl + '/' + slug);
        };

        this.getUpcomingEvents = function (limit = 10) {
            return ApiService.getPublic(baseUrl + '/upcoming?limit=' + limit);
        };

        this.getHighlighted = function (query) {
            return ApiService.query(baseUrl + '/highlighted', query);
        };

        this.search = function (query) {
            return ApiService.query(baseUrl + '/paged', query);
        };
    }
})();
