(function () {
    angular.module('maps-service', []).service('MapsService', ['$window', '$q', '$rootScope', MapsService]);

    function MapsService($window, $q, $rootScope) {
        window.mapsServiceLoaded = true;
        let key;
        let version;

        if ($rootScope.mapConfig) {
            key = $rootScope.mapConfig.key ? $rootScope.mapConfig.key : null;
            version = $rootScope.mapConfig.version ? $rootScope.mapConfig.version : '3';
        }
        else {
            key = 'AIzaSyA0QH6Ap1GOLUZpy0ChFGlCinagV_NahR0';
            version = '3';
        }

        const callback = 'initMapService';
        const mapsUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places&v=' + version + '&key=' + key + '&callback=' + callback;
        const mapsMarkerUrl = 'assets/libs/gmaps-markerwithlabel.min.js';
        let defer;

        function loadScript() {
            if (typeof google === 'object' && typeof google.maps === 'object') {
                defer.resolve();
                return;
            }

            const s = document.createElement('script');
            s.src = mapsUrl;
            s.setAttribute('async', '');
            s.setAttribute('defer', '');
            document.body.appendChild(s);
        }

        function loadMarkersLabelScript(fn) {
            const s = document.createElement('script');
            s.src = mapsMarkerUrl;
            s.setAttribute('async', '');
            s.setAttribute('defer', '');
            document.body.appendChild(s);
            s.onload = function () {
                if (fn && typeof fn === 'function') {
                    fn();
                }
            };
        }

        $window.initMapService = () => {
            loadMarkersLabelScript(() => {
                defer.resolve();
            });
        };

        this.load = () => {
            defer = $q.defer();
            loadScript();

            return defer.promise;
        };
    }
})();
