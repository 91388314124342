(function () {
    angular.module('alterWebApp').directive('photoSphereDirective', ($rootScope, $timeout, $window, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                data: '=',
                index: '=',
                ratio: '='
            },
            templateUrl: 'app/shared/photo-sphere/template.html',
            link: (scope, element) => {
                scope.image = scope.data;
                scope.id = 'img' + scope.image.id;

                $timeout(() => {
                    scope.viewer = new PhotoSphereViewer({
                        container: scope.id,
                        navbar: false,
                        loading_txt: '',
                        anim_speed: 0,
                        cache_texture: 2,
                        mousewheel: false
                    });

                    scope.viewer.preloadPanorama(scope.image.file);

                    if (scope.index === 0) {
                        scope.viewer.setPanorama(scope.image.file);
                    }

                    scope.$on(`photoSphereDirective:resume${scope.data.id}`, () => {
                        scope.viewer.setPanorama(scope.image.file);
                    });
                });
            }
        };
    });
})();
