(function () {
    angular.module('alterWebApp').constant('GeneralConst', {
        ENTER_KEY: 13,
        DEFAULT_LANG: 'pt',
        EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
        IMAGE_SIZE: {
            SQUARE: 'SQUARE',
            PORTRAIT: 'PORTRAIT',
            LANDSCAPE: 'LANDSCAPE',
            PHONE: 'PHONE'
        },
        ALL_ENUM: 'all',
        PERMANENT_EVENTS_ENUM: 'PERMANENT',
        TEMPORARY_EVENTS_ENUM: 'TEMPORARY',
        NUCLEUS_POINT_TYPE: 'PointOfInterestMuseum',
        INDOOR_POINT_TYPE: 'PointOfInterestIndoor',
        OUTDOOR_POINT_TYPE: 'PointOfInterestOutdoor'
    });
})();
