(function () {
    angular.module('alterWebApp').filter('customDate', ($rootScope, Utils) => {
        function getCurrentIsoCode() {
            if (!$rootScope.currentLanguage) {
                return null;
            }

            return $rootScope.currentLanguage.getCode();
        }

        return (d) => {
            const monthFormat = ($rootScope.isMobile ? 'MMM' : 'MMMM');
            return `${parseInt(moment(d).format('DD'), 10)} ${Utils.capitalize(moment(d).format(monthFormat))} ${moment(d).format('YYYY')}`;
        };
    });
})();
