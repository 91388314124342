(function () {
    angular.module('alterWebApp').service('HighlightsService', HighlightsService);

    function HighlightsService(ApiService, $rootScope) {
        const baseUrl = '/api/v1/highlights';

        this.getSlider = function () {
            return ApiService.getPublic(baseUrl + '/slider');
        };
    }
})();
