(function () {
  angular.module("alterWebApp").controller("HomeController", HomeController);

  function HomeController(
    $scope,
    $rootScope,
    $timeout,
    Point,
    PointsService,
    $filter,
    News,
    NewsService,
    Event,
    EventsService,
    Highlight,
    HighlightsService,
    Utils
  ) {
    const self = this;
    $scope.socialNetworks = $rootScope.socialNetworks;

    self.images = [];
    self.video = {
      placeholder: "assets/img/video-placeholder.jpg",
      src: null,
      state: "points",
    };

    self.highlightedPoints = [];
    self.recentNews = [];
    self.upcomingEvents = [];

    self.initView = function () {
      self.video = {
        src: $rootScope.mainInfo.file,
        placeholder: "assets/img/video-placeholder.jpg",
        autoplay: true,
        muted: true,
        loop: true,
        sticky: true,
      };

      HighlightsService.getSlider()
        .then((data) => {
          if (!data.data.data.length) {
            const highlight = new Highlight({
              id: 1,
              title: $rootScope.stringsCollection.welcomeTitle,
              excerpt: $rootScope.stringsCollection.welcomeText,
              order: 0,
            });
            self.images = [highlight];
            return;
          }

          self.images = data.data.data.map((img) => {
            const highlightObj = new Highlight(img);
            return highlightObj;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      PointsService.getHighlightedPoints()
        .then((data) => {
          if (!data.data.data.length) {
            return;
          }

          self.highlightedPoints = data.data.data.map((p) => {
            const pointObj = new Point(p);
            pointObj.href = Utils.buildSref(pointObj);
            return pointObj;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      NewsService.getRecentNews()
        .then((data) => {
          if (!data.data.data.length) {
            return;
          }

          self.recentNews = data.data.data.map((n) => {
            const newsObj = new News(n);
            newsObj.href = Utils.buildSref(newsObj);
            return newsObj;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      EventsService.getUpcomingEvents()
        .then((data) => {
          if (!data.data.data.length) {
            return;
          }

          self.upcomingEvents = data.data.data.map((e) => {
            const ev = new Event(e);
            ev.href = Utils.buildSref(ev);
            return ev;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
  }
})();
