(function () {
    angular.module('alterWebApp').directive('eventsCarouselDirective', ($rootScope, $timeout, $window, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                items: '=',
                navigation: '@',
                itemsPerPage: '@'
            },
            templateUrl: 'app/shared/events-carousel/template.html',
            link: (scope, element) => {
                scope.isReady = false;
                scope.draggable = false;
                const defaultPp = $rootScope.isMobile ? 1 : 3;

                scope.getPortraitCover = (fileId) => {
                    if (!fileId) {
                        return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.PORTRAIT);
                    }

                    return FileService.getPortraitThumbnail(fileId);
                };

                scope.sliderSettings = {
                    dimensions: {
                        item: 0
                    },
                    hammerOptions: {},
                    itemsPerPage: scope.itemsPerPage ? scope.itemsPerPage : defaultPp
                };

                scope.domElements = {
                    wrapper: null,
                    cards: null,
                    navEl: null
                };

                class Navigation {
                    constructor(el, settings) {
                        this.DOM = { el };

                        this.settings = {
                            next: () => false,
                            prev: () => false,
                            navigateTo: () => false
                        };

                        Object.assign(this.settings, settings);
                        this.DOM.navigationItems = [];
                        this.totalDots = Math.ceil(scope.domElements.cards.length / scope.sliderSettings.itemsPerPage);

                        for (let i = 0; i < this.totalDots; i += 1) {
                            const dot = document.createElement('button');
                            dot.classList.add('navigation-dot');

                            dot.addEventListener('click', (event) => {
                                if (event.target.classList.contains('current')) {
                                    return;
                                }

                                let itemIndex = 0;

                                if (i > 0) {
                                    itemIndex = i * scope.sliderSettings.itemsPerPage;
                                }

                                this.settings.navigateTo(itemIndex);
                            });

                            this.DOM.navigationItems.push(dot);
                            scope.domElements.navEl.appendChild(dot);
                        }

                        this.setCurrent(0);
                    }

                    setCurrent(index, direction, previous) {
                        const next = Math.max(0, Math.floor(index));
                        const prev = Math.max(0, Math.floor(previous));

                        if ($rootScope.isMobile) {
                            if (typeof scope.domElements.cards[prev] !== 'undefined') {
                                scope.domElements.cards[prev].classList.remove('current');
                            }

                            if (typeof scope.domElements.cards[next] !== 'undefined') {
                                scope.domElements.cards[next].classList.add('current');
                            }
                        }
                        else {
                            if (typeof previous !== 'undefined' && typeof this.DOM.navigationItems[prev] !== 'undefined') {
                                this.DOM.navigationItems[prev].classList.remove('current');
                            }

                            if (typeof this.DOM.navigationItems[next] !== 'undefined') {
                                this.DOM.navigationItems[next].classList.add('current');
                            }
                        }
                    }
                }

                class Slideshow {
                    constructor(el) {
                        this.DOM = { el };
                        this.totalSlides = scope.domElements.cards.length;
                        this.sensitivity = 25; // % to trigger swipe

                        if (this.totalSlides <= scope.sliderSettings.itemsPerPage) {
                            return false;
                        }

                        if (scope.domElements.navEl) {
                            this.navigation = new Navigation(scope.domElements.navEl, {
                                next: () => this.navigate('right'),
                                prev: () => this.navigate('left'),
                                navigateTo: (index) => {
                                    this.navigate(index);
                                }
                            });
                        }

                        this.sliderManager = new Hammer.Manager(this.DOM.el, {
                            touchAction: 'auto',
                            recognizers: [
                                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
                            ]
                        });

                        this.sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
                        scope.draggable = true;

                        this.DOM.el.querySelectorAll('.vertical-card > a').ondragstart = () => false;
                        this.sliderManager.on('panright panleft', (e) => {
                            e.preventDefault();

                            if (this.DOM.el.classList.contains('disabled')) {
                                return;
                            }

                            this.DOM.el.classList.add('disabled');
                            if (e.type === 'panleft') {
                                this.navigate(this.current + scope.sliderSettings.itemsPerPage);
                            }
                            else if (e.type === 'panright') {
                                this.navigate(this.current - scope.sliderSettings.itemsPerPage);
                            }

                            $timeout(() => {
                                this.DOM.el.classList.remove('disabled');
                            }, 400);
                        });

                        this.current = 0;
                    }

                    navigate(nextSlide) {
                        let direction = null;
                        let offsetX = 0;

                        if (this.isAnimating || nextSlide < 0) {
                            return;
                        }

                        if (this.current < nextSlide) {
                            direction = 'right';
                            if (this.current + scope.sliderSettings.itemsPerPage >= this.totalSlides) {
                                return;
                            }
                        }
                        else {
                            direction = 'left';
                        }

                        this.isAnimating = true;

                        const nextNavIndex = nextSlide / scope.sliderSettings.itemsPerPage;
                        const prevNavIndex = this.current / scope.sliderSettings.itemsPerPage;
                        offsetX = scope.sliderSettings.dimensions.item * nextSlide;

                        if (offsetX > scope.sliderSettings.dimensions.item * (this.totalSlides - scope.sliderSettings.itemsPerPage)) {
                            offsetX = scope.sliderSettings.dimensions.item * (this.totalSlides - scope.sliderSettings.itemsPerPage);
                        }

                        if ($rootScope.isMobile && (offsetX + scope.sliderSettings.dimensions.item) > (scope.sliderSettings.dimensions.item * this.totalSlides - 1)) {
                            const item = scope.domElements.cards[this.current];
                            const itemAxis = item.currentStyle || $window.getComputedStyle(item);
                            const margins = parseFloat(itemAxis.marginRight) * (this.totalSlides - 1);

                            const highlightedPoints = document.querySelector('section.highlighted-points');
                            const highlightedAxis = highlightedPoints.currentStyle || $window.getComputedStyle(highlightedPoints);
                            const containerPadding = parseFloat(highlightedAxis.paddingLeft);

                            offsetX = scope.domElements.cards[this.current].offsetLeft + (margins + containerPadding);
                        }

                        TweenMax.to(this.DOM.el, 0.8, {
                            ease: 'Expo.easeOut',
                            x: -(offsetX),
                            onComplete: () => {
                                this.isAnimating = false;
                                this.current = nextSlide;
                            }
                        });

                        this.navigation.setCurrent(nextNavIndex, direction, prevNavIndex);
                    }
                }

                $window.addEventListener('resize', setSliderDimensions);
                function setSliderDimensions() {
                    $timeout(() => {
                        const carouselItem = scope.domElements.cards[0];
                        const style = carouselItem.currentStyle || $window.getComputedStyle(carouselItem);
                        const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
                        const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
                        const width = carouselItem.offsetWidth;

                        scope.sliderSettings.dimensions.item = width + margin - padding;

                        if (window.innerWidth <= (width + margin - padding) * scope.sliderSettings.itemsPerPage) {
                            scope.sliderSettings.itemsPerPage = 1;
                        }
                    });
                }

                scope.$watch('items', init, true);

                function init() {
                    if (!scope.items.length) {
                        return;
                    }

                    $timeout(() => {
                        scope.domElements = {
                            wrapper: element[0],
                            cards: element[0].getElementsByClassName('vertical-card'),
                            navEl: document.getElementById(scope.navigation)
                        };

                        scope.current = scope.items[0];
                        $window.dispatchEvent(new Event('resize'));

                        scope.$on('$destroy', () => {
                            window.removeEventListener('resize', setSliderDimensions);
                        });

                        const eventsCarousel = new Slideshow(scope.domElements.wrapper.querySelector('.carousel-wrapper'));
                        scope.isReady = true;
                    });
                }
            }
        };
    });
})();
