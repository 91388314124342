
(function () {
    angular.module('alterWebApp').service('FileService', FileService);

    function FileService(ApiService, GeneralConst) {
        const baseUrl = '/api/v1/file';

        this.getUrl = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id;
        };

        this.getThumbnail = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id + '/thumbnail';
        };

        this.getFhd = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id + '/full-hd';
        };

        this.getLandscape = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id + '/landscape';
        };

        this.getPortrait = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id + '/portrait';
        };

        this.getPortraitThumbnail = function (id) {
            return ApiService.getFullHost() + baseUrl + '/' + id + '/thumbnail-portrait';
        };

        this.getStaticImagePath = function (name) {
            const assets = 'assets/img/';
            return assets + name;
        };

        this.getDefaultImageUrl = function (size) {
            // TODO: replace names for final names.
            let url = 'assets/img/';
            switch (size) {
            case GeneralConst.IMAGE_SIZE.SQUARE:
                url += 'placeholder-square.png';
                break;
            case GeneralConst.IMAGE_SIZE.PORTRAIT:
                url += 'placeholder-portrait.png';
                break;
            case GeneralConst.IMAGE_SIZE.LANDSCAPE:
                url += 'placeholder-landscape.png';
                break;
            case GeneralConst.IMAGE_SIZE.PHONE:
                url += 'placeholder-app.png';
                break;
            default:
                url += 'placeholder.png';
                break;
            }
            return url;
        };
    }
})();
