(function () {
    angular.module('alterWebApp').controller('SingleEventController', SingleEventController);

    function SingleEventController($scope, $q, $rootScope, $state, $filter, $stateParams, $timeout, FileService, Event, Utils, EventsService) {
        const self = this;
        self.isReady = false;
        self.event = null;
        self.images = [];
        self.upcomingEvents = [];
        self.galleryRatio = ($rootScope.isMobile ? '3:2' : '3:1');
        self.currentUrl = '';

        self.initView = () => {
            if (angular.isUndefined($stateParams.slug)) {
                $state.go('home');
            }

            EventsService.get($stateParams.slug)
                .then((data) => {
                    const event = new Event(data.data.data);
                    self.event = event;
                    if (event.slug !== $state.params.slug) {
                        $state.go('app.eventProfile', { lang: $rootScope.currentLanguage.getCode(), slug: event.slug }, { reload: false });
                    }

                    if (typeof event.gallery !== 'undefined' && event.gallery.length) {
                        self.images = buildGallery(event.gallery);
                    }

                    self.currentUrl = Utils.getRoute($state, self.event, $rootScope.currentLanguage.getCode());
                })
                .catch((error) => {});

            EventsService.getUpcomingEvents(4)
                .then((data) => {
                    if (!data.data.data.length) {
                        return;
                    }

                    self.upcomingEvents = data.data.data.map((e) => {
                        const ev = new Event(e);
                        ev.href = Utils.buildSref(ev);
                        return ev;
                    });
                })
                .catch((error) => {});

            $timeout(() => {
                const tips = document.getElementsByClassName('share-btn');
                if (tips.length) {
                    for (let i = 0; i < tips.length; i += 1) {
                        const content = tips[i].getAttribute('data-tippy-content');

                        if (content) {
                            tippy(tips[i], { content });
                            const currentTip = tips[i]._tippy;
                            tips[i].addEventListener('click', ($ev) => {
                                $ev.preventDefault();
                                currentTip.setContent(tips[i].getAttribute('data-tippy-click-content'));
                                setTimeout(() => {
                                    currentTip.show();
                                    setTimeout(() => {
                                        currentTip.setContent(content);
                                    }, 3500);
                                }, 10);
                            });
                        }
                    }
                }
            });
        };

        self.onCopyClick = ($ev) => {
            $ev.preventDefault();
            Utils.copyToClipboard(self.currentUrl);
        };

        self.getCurrentUrl = () => {
            return Utils.copyToClipboard(self.currentUrl);
        };

        function buildGallery(images) {
            if (!images.length) {
                return [];
            }

            const item = $filter('orderBy')(images, ['-isCoverImage', 'order'])[0];
            const arr = [{ file: FileService.getLandscape(item.file), title: item.title }];
            return arr;
        }
    }
})();
