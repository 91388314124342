(function () {
    angular.module('alterWebApp').directive('yearsCarouselDirective', ($rootScope, $timeout, $window, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                disabled: '@',
                filter: '=',
                current: '=',
                callback: '='
            },
            templateUrl: 'app/shared/years-carousel-list/template.html',
            link: (scope, element) => {
                if (!scope.callback || !scope.filter) {
                    return;
                }

                scope.isReady = false;
                scope.years = [];
                scope.minYear = scope.filter.min || scope.filter.currentYear - 1;
                scope.maxYear = scope.filter.max || scope.filter.currentYear + 1;
                scope.carouselEl = element[0].getElementsByClassName('years-carousel')[0];
                scope.lastOffset = 0;

                for (let i = scope.minYear; i <= scope.maxYear; i += 1) {
                    scope.years.push(i.toString());
                }

                $timeout(() => {
                    const initialItem = scope.years.findIndex(y => y === scope.current);
                    scope.slideTo(null, initialItem);
                });

                scope.formatString = (str) => {
                    if (str === scope.current) {
                        return str;
                    }

                    return str.slice(-2);
                };

                scope.slideTo = (ev, index) => {
                    const selectedYear = parseInt(scope.years[index], 10);
                    scope.callback(ev, selectedYear.toString());

                    if (scope.years.length < 4) {
                        return;
                    }

                    const slideEl = document.getElementById('year' + selectedYear.toString());
                    const style = slideEl.currentStyle || $window.getComputedStyle(slideEl);
                    const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
                    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
                    const width = slideEl.offsetWidth;
                    const slideElWidth = width + margin - padding;
                    let offsetX = 0;

                    if (selectedYear > scope.minYear && (selectedYear + 1) <= scope.maxYear) {
                        offsetX = slideElWidth * (index - 1);
                    }
                    else if ((selectedYear + 1) > scope.maxYear) {
                        offsetX = scope.lastOffset;
                    }

                    scope.lastOffset = offsetX;
                    TweenMax.to(scope.carouselEl, 0.5, {
                        ease: 'Expo.easeOut',
                        x: -(offsetX)
                    });
                };
            }
        };
    });
})();
