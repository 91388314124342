(function () {
    angular.module('alterWebApp').service('Utils', function ($rootScope, $location, $window) {
        const self = this;

        this.isValid = function (text) {
            if (typeof text === 'undefined' || text == null) {
                return false;
            }
            return true;
        };

        this.initValue = function (value) {
            if (typeof value === 'undefined' || value == null || value === '') {
                return null;
            }
            return value;
        };

        this.getCurrentLanguageObject = function (languages) {
            for (let i = 0; i < languages.length; i += 1) {
                if (languages[i].language === $rootScope.currentLanguage.getId()) {
                    return languages[i];
                }
            }
            return null;
        };

        this.loadScript = function (url, callback) {
            const script = document.createElement('script');
            script.setAttribute('src', url);
            document.body.appendChild(script);

            if (callback) {
                script.onload = callback;
            }
        };

        this.trimText = function (name, length, ellipsis = true) {
            if (name.length <= length) {
                return name;
            }

            const subString = name.substr(0, length - 1);
            return subString + (ellipsis ? '..' : '');
        };

        this.loadMomentLocaleByCode = function (isoCode, callback) {
            let c = isoCode;
            if (isoCode === 'en') {
                c += '-gb';
            }

            const url = 'bower_components/moment/locale/' + c + '.js';

            return self.loadScript(url, (e) => {
                return callback();
            });
        };

        this.isElementOnScreen = function (element) {
            const rect = element.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        };

        this.capitalize = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        this.onScreen = function (elem, percentage = 1) {
            // percentage - i.e '20'% of the item is visible add class , 0 if add class immediatly
            const rect = elem.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return !(rect.bottom < 100 || rect.top - viewHeight >= -(elem.offsetHeight * percentage / 100));
        };

        this.isMobile = function (device = false) {
            if (device) {
                return navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i)
                || $window.innerWidth < 1024 || false;
            }

            return $window.innerWidth < 1024 || false;
        };

        this.getDomain = function () {
            return $rootScope.ENV.currentHost 
                ? $rootScope.ENV.currentHost 
                : $location.protocol() + '://' + $location.host() + ($location.port() !== '80' ? ':' + $location.port() : ''
            );
        };

        this.buildLangSref = function (route, isoCode) {
            return `${route}({ lang:'${isoCode}' })`;
        };

        this.getRoute = function ($state, item, isoCode, keys = ['slug']) {
            const obj = {
                lang: isoCode
            };

            if (!item.state) {
                return '/#!/';
            }

            for (let i = 0; i < keys.length; i += 1) {
                const attr = keys[i];
                if (typeof item[attr] !== 'undefined' && item[attr]) {
                    obj[attr] = item[attr];
                }
            }

            return $state.href(item.state, obj);
        };

        this.buildSref = function (item, isoCode, keys = ['slug']) {
            const obj = {
                lang: isoCode
            };

            if (typeof item.href !== 'undefined' && item.href) {
                return item.href;
            }

            for (let i = 0; i < keys.length; i += 1) {
                const attr = keys[i];
                if (typeof item[attr] !== 'undefined') {
                    obj[attr] = item[attr];
                }
            }

            return '' + item.state + '(' + JSON.stringify(obj) + ')';
        };

        this.getMonthsArray = function () {
            const months = moment.months().map((m, index) => {
                const monthIndex = index + 1;
                const monthObj = {
                    name: m,
                    value: monthIndex,
                    order: monthIndex
                };

                return monthObj;
            });

            return months;
        };

        /*
        * Params:
        *   1 - element to calculate scrollTop
        *   2 - percentage to compare, i.e if scrolled down to 75% of the elem
        */
        this.closeToBottom = function (elem, percentage = 75) {
            if (!elem) {
                return false;
            }

            const rect = elem.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return !(rect.bottom < 100 || rect.top - viewHeight >= -(elem.offsetHeight * percentage / 100));
        };

        this.copyToClipboard = function (path) {
            const el = document.createElement('textarea');
            el.value = this.getDomain() + path;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';

            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        };
    });
})();
