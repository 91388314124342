(function () {
    angular.module('alterWebApp').directive('squareCardDirective', (FileService, GeneralConst, Utils, $timeout) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                data: '='
            },
            templateUrl: 'app/shared/square-card/template.html',
            link: (scope, element, attrs) => {
                scope.isReady = false;
                scope.getThumbnail = (fileId) => {
                    if (!fileId) {
                        return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.SQUARE);
                    }

                    return FileService.getThumbnail(fileId);
                };

                scope.$watch('data', init, true);

                function init() {
                    if (!scope.data) {
                        return;
                    }

                    if (!scope.data.title) {
                        return;
                    }

                    scope.isReady = true;
                }
            }
        };
    });
})();
