(function () {
    angular.module('alterWebApp').factory('Event', EventModel);

    function EventModel(Utils, $rootScope, LanguageService, GeneralConst) {
        function Event(data) {
            if (data) {
                this.setData(data);
            }
        }

        Event.prototype = {
            setData(data) {
                this.id = data._id;
                this.type = 'event';
                this.slug = Utils.initValue(data.slug);
                this.title = Utils.initValue(data.title);
                this.category = Utils.initValue(data.category);
                this.author = Utils.initValue(data.author);
                this.parent = Utils.initValue(data.relatedPoint);
                this.excerpt = Utils.initValue(data.excerpt) ? Utils.initValue(data.excerpt) : this.description;
                this.description = Utils.initValue(data.description);
                this.gallery = Utils.initValue(data.gallery);
                this.kind = Utils.initValue(data.kind);
                this.contacts = Utils.initValue(data.contacts);

                if (this.kind !== GeneralConst.PERMANENT_EVENTS_ENUM) {
                    this.startDate = Utils.initValue(data.startDate);
                    this.endDate = Utils.initValue(data.endDate);
                    this.date = this.startDate;
                }
                else {
                    this.date = null;
                    this.startDate = null;
                    this.endDate = null;
                }

                if (this.parent) {
                    const ev = new Event(this.parent);
                    ev.href = Utils.buildSref(ev, LanguageService.getCurrentLanguage());
                    this.parent = ev;
                }

                this.state = 'app.eventProfile';
                this.coverImage = Utils.initValue(data.coverImage) ? data.coverImage.file : this.getCoverImage();
                this.category = this.getCategory();
            },
            getId() {
                return this.id;
            },
            getTitle() {
                if (this.languages === null) {
                    return '';
                }

                return angular.lowercase(this.languages.title);
            },
            getCategory() {
                return this.category && this.category.title ? this.category.title : null;
            },
            getCoverImage() {
                if (!this.gallery || !this.gallery.length) {
                    return null;
                }

                const image = this.gallery.filter((img) => {
                    return img.isCoverImage === true;
                });

                if (!image.length) {
                    return this.gallery[0].file;
                }

                return image[0].file;
            }
        };

        return Event;
    }
})();
