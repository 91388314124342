(function () {
    angular.module('alterWebApp').directive('appDirective', ($rootScope, $sce, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {},
            templateUrl: 'app/shared/app-banner/template.html',
            link: (scope, element, attrs) => {
                scope.title = $rootScope.appInfo.title;
                scope.isVisible = false;

                if ($rootScope.appInfo.file) {
                    scope.image = FileService.getUrl($rootScope.appInfo.file);
                }
                else {
                    scope.image = FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.PHONE);
                }

                scope.description = $sce.trustAsHtml($rootScope.appInfo.description);
                scope.appStoreLink = $rootScope.appInfo.appStoreLink;
                scope.playStoreLink = $rootScope.appInfo.playStoreLink;
            }
        };
    });
})();
