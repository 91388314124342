(function () {
    angular.module('alterWebApp').factory('Language', LanguageModel);

    function LanguageModel() {
        function Language(data) {
            if (data) {
                this.setData(data);
            }
        }

        Language.prototype = {
            setData(data) {
                this.id = data._id;
                this.englishName = data.englishName;
                this.isoCode = data.isoCode;
                this.languageName = data.languageName;
                this.nativeName = data.nativeName;
                this.countryCode = data.countryCode;
                this.default = data.isDefault;
            },
            getId() {
                return this.id;
            },
            getTitle() {
                return this.nativeName;
            },
            getCode() {
                return this.isoCode;
            },
            getFlagUrl() {
                return 'https://www.countryflags.io/' + this.countryCode + '/flat/64.png';
            },
            isDefault() {
                return this.default === true;
            }
        };

        return Language;
    }
})();
