(function () {
    angular.module('alterWebApp').service('NewsService', NewsService);

    function NewsService(ApiService, $rootScope) {
        const baseUrl = '/api/v1/news';

        this.get = function (slug) {
            return ApiService.getPublic(baseUrl + '/' + slug);
        };

        this.getRecentNews = function (limit = 4) {
            return ApiService.getPublic(baseUrl + '/last?limit=' + limit);
        };

        this.getHighlighted = function (query) {
            return ApiService.query(baseUrl + '/highlighted', query);
        };

        this.search = function (query) {
            return ApiService.query(baseUrl + '/paged', query);
        };
    }
})();
