(function () {
    angular.module('onscreen-directive', []).directive('onScreen', ($window) => {
        return {
            restrict: 'A',
            transclude: false,
            scope: {
                visiblePercentage: '=onScreen' // i.e "20"% of the item is visible add class , 0 if add class immediatly
            },
            link: (scope, element) => {
                const el = element[0];
                angular.element($window).on('scroll', (e) => {
                    if (!el.classList.contains('on-screen')) {
                        if (onScreen(el)) {
                            el.classList.add('on-screen');
                        }
                    }
                });

                function onScreen(elem) {
                    const rect = elem.getBoundingClientRect();
                    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                    return !(rect.bottom < 100 || rect.top - viewHeight >= -(elem.offsetHeight * scope.visiblePercentage / 100));
                }
            }
        };
    });
})();
