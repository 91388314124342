(function () {
    angular.module('alterWebApp').controller('VisitNucleusController', VisitNucleusController);

    function VisitNucleusController($scope, $state, $rootScope, $stateParams, Point, PointsService, Utils, LanguageService) {
        const self = this;
        $scope.points = [];

        $scope.floor = []

        self.initView = () => {
            if (angular.isUndefined($stateParams.slug)) {
                $state.go('home');
            }

            if ($scope.$parent.point) {
                const parent = $scope.$parent.point.id;
                PointsService.getChildren({ parent })
                    .then((data) => {
                        $scope.points = data.data.data.forEach(p => {

                            parentFloor = p._id

                            
                            PointsService.getChildrenFloor({ parentFloor }).then((data) => {
                                data.data.data.map((p2) => {
                                    console.log("teste------->", p2)


                                    const n = new Point(p2);
                                    n.href = Utils.buildSref(n, LanguageService.getCurrentLanguage());
                                    $scope.floor.push(n);
                                })

                            })

                        });
                    })
                    .catch((error) => {
                        ;
                    });
            }
        };
    }
})();
