(function () {
    angular.module('alterWebApp').directive('galleryDirective', ($rootScope, $timeout, $window, FileService, GeneralConst) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                items: '=',
                navigation: '@',
                ratio: '='
            },
            templateUrl: 'app/shared/gallery/template.html',
            link: (scope, element) => {
                scope.isReady = false;
                scope.domElements = {
                    wrapper: null,
                    slides: null,
                    navEl: null,
                    images: null
                };

                scope.sliderSettings = {
                    dimensions: {
                        item: 0
                    },
                    sliderHeight: { height: 'auto' },
                    hammerOptions: {},
                    itemsPerPage: scope.itemsPerPage ? scope.itemsPerPage : 1
                };

                scope.label = null;

                class GalleryItem {
                    constructor(el, index, settings) {
                        this.DOM = { el };
                        this.settings = {};
                        this.slideIndex = index;

                        Object.assign(this.settings, settings);
                        this.DOM.wrap = this.DOM.el.querySelector('.slide-wrap');

                        this.config = {
                            animation: {
                                duration: 1.2,
                                eause: Expo.easeInOut
                            }
                        };
                    }

                    setCurrent(isCurrent = true) {
                        this.DOM.el.classList[isCurrent ? 'add' : 'remove']('slide--current');
                    }

                    hide(direction) {
                        return this.toggle('hide', direction);
                    }

                    show(direction) {
                        this.DOM.el.style.zIndex = 998;
                        return this.toggle('show', direction);
                    }

                    toggle(action, direction) {
                        return new Promise((resolve) => {
                            TweenMax.to(this.DOM.wrap, this.config.animation.duration, {
                                ease: this.config.animation.ease,
                                startAt: action === 'hide' ? {} : { x: direction === 'right' ? '-100%' : '100%' },
                                x: '0%',
                                onStart: () => {
                                    if (action === 'hide') {
                                        if (direction === 'right') {
                                            this.DOM.wrap.style.transformOrigin = '100% 50%';
                                        }
                                        else {
                                            this.DOM.wrap.style.transformOrigin = '0% 50%';
                                        }
                                    }
                                    else {
                                        if (direction === 'right') {
                                            this.DOM.wrap.style.transformOrigin = '0% 50%';
                                        }
                                        else {
                                            this.DOM.wrap.style.transformOrigin = '100% 50%';
                                        }
                                    }
                                    this.DOM.el.style.opacity = 1;
                                },
                                onComplete: () => {
                                    this.DOM.el.style.zIndex = 999;
                                    this.DOM.el.style.opacity = action === 'hide' ? 0 : 1;
                                    resolve();
                                }
                            });
                        });
                    }
                }

                class GalleryNavigation {
                    constructor(el, settings) {
                        this.DOM = { el };

                        this.settings = {
                            next: () => false,
                            prev: () => false,
                            navigateTo: () => false
                        };

                        Object.assign(this.settings, settings);
                        this.DOM.navigationItems = [];
                        this.totalDots = scope.domElements.slides.length;

                        for (let i = 0; i < this.totalDots; i += 1) {
                            const dot = document.createElement('button');
                            dot.classList.add('navigation-dot');
                            if (scope.items[i].isPanorama) {
                                const label = document.createElement('label');
                                label.innerHTML = '360';
                                dot.appendChild(label);
                            }

                            dot.addEventListener('click', (event) => {
                                if (event.target.classList.contains('current')) {
                                    return;
                                }
                                this.settings.navigateTo(i);
                            });

                            this.DOM.navigationItems.push(dot);
                            scope.domElements.navEl.appendChild(dot);
                        }

                        const arrowPrev = document.createElement('button');
                        const iconPrev = document.createElement('i');
                        arrowPrev.classList.add('navigation-arrow', 'navigate-prev');
                        iconPrev.classList.add('icon-small-arrow-left');
                        arrowPrev.appendChild(iconPrev);
                        arrowPrev.addEventListener('click', () => this.settings.prev());

                        const arrowNext = document.createElement('button');
                        const iconNext = document.createElement('i');
                        arrowNext.classList.add('navigation-arrow', 'navigate-next');
                        iconNext.classList.add('icon-small-arrow-right');
                        arrowNext.appendChild(iconNext);
                        arrowNext.addEventListener('click', () => this.settings.next());

                        if (!$rootScope.isMobile) {
                            scope.domElements.navEl.insertBefore(arrowPrev, scope.domElements.navEl.firstChild);
                            scope.domElements.navEl.appendChild(arrowNext);
                        }
                        else {
                            scope.domElements.wrapper.appendChild(arrowPrev);
                            scope.domElements.wrapper.appendChild(arrowNext);
                        }

                        this.setCurrent(0);
                    }

                    setCurrent(index, direction, previous) {
                        const next = Math.max(0, Math.floor(index));
                        const prev = Math.max(0, Math.floor(previous));
                        if (typeof previous !== 'undefined') {
                            this.DOM.navigationItems[prev].classList.remove('current');
                        }

                        if (scope.items[next].isPanorama) {
                            $rootScope.$broadcast(`photoSphereDirective:resume${scope.items[next].id}`);
                        }

                        this.DOM.navigationItems[next].classList.add('current');
                    }
                }

                class Gallery {
                    constructor(el) {
                        this.DOM = { el };
                        this.totalSlides = scope.domElements.slides.length;
                        if (this.totalSlides > 1 && scope.domElements.navEl) {
                            this.navigation = new GalleryNavigation(scope.domElements.navEl, {
                                next: () => this.navigate('right'),
                                prev: () => this.navigate('left'),
                                navigateTo: (index) => {
                                    this.navigate(index);
                                }
                            });
                        }

                        this.slides = [];
                        Array.from(this.DOM.el.querySelectorAll('.slide')).forEach((slideEl, pos) => {
                            this.slides.push(new GalleryItem(slideEl, pos));
                        });

                        this.current = 0;

                        $timeout(() => {
                            scope.label = scope.items[0].title;
                        });
                    }

                    navigate(nextSlide) {
                        let direction = null;
                        let offsetX = 0;
                        let nextSlideIndex = null;

                        if (this.isAnimating) {
                            return;
                        }

                        if (!Number.isInteger(nextSlide)) {
                            if (nextSlide === 'right') {
                                nextSlideIndex = this.current + 1;
                            }
                            else if (nextSlide === 'left') {
                                nextSlideIndex = this.current - 1;
                            }
                            else {
                                return;
                            }
                        }
                        else {
                            nextSlideIndex = nextSlide;
                        }

                        if (this.current < nextSlideIndex) {
                            direction = 'right';
                            if (this.current + scope.sliderSettings.itemsPerPage >= this.totalSlides) {
                                nextSlideIndex = 0;
                            }
                        }
                        else {
                            direction = 'left';
                            if (this.current - scope.sliderSettings.itemsPerPage < 0) {
                                nextSlideIndex = this.totalSlides - 1;
                            }
                        }

                        this.isAnimating = true;

                        const nextNavIndex = nextSlideIndex / scope.sliderSettings.itemsPerPage;
                        const prevNavIndex = this.current / scope.sliderSettings.itemsPerPage;
                        offsetX = scope.sliderSettings.dimensions.item * nextSlideIndex;

                        if (offsetX > scope.sliderSettings.dimensions.item * (this.totalSlides - scope.sliderSettings.itemsPerPage)) {
                            offsetX = scope.sliderSettings.dimensions.item * (this.totalSlides - scope.sliderSettings.itemsPerPage);
                        }


                        TweenMax.to(this.DOM.el, 0.8, {
                            ease: 'Expo.easeOut',
                            x: -(offsetX),
                            onComplete: () => {
                                this.isAnimating = false;
                                this.current = nextSlideIndex;
                                scope.label = scope.items[nextSlideIndex].title;
                            }
                        });

                        this.navigation.setCurrent(nextNavIndex, direction, prevNavIndex);
                    }
                }

                function setSliderDimensions() {
                    $timeout(() => {
                        if (!scope.ratio) {
                            const navH = document.getElementById('mainNav').clientHeight;
                            scope.sliderSettings.sliderHeight = { height: ($window.innerHeight - navH) + 'px' };
                        }

                        const carouselItem = scope.domElements.slides[0];
                        const style = carouselItem.currentStyle || $window.getComputedStyle(carouselItem);
                        const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
                        const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
                        const width = carouselItem.offsetWidth;
                        scope.sliderSettings.dimensions.item = width + margin - padding;

                        if (window.innerWidth <= (width + margin - padding) * scope.sliderSettings.itemsPerPage) {
                            scope.sliderSettings.itemsPerPage = 1;
                        }
                    });
                }

                scope.$watch('items', init, true);
                function init() {
                    if (!scope.items.length) {
                        return;
                    }

                    let navEl;
                    if (typeof scope.navigation !== 'undefined' && scope.navigation) {
                        navEl = document.getElementById(scope.navigation);
                    }

                    scope.domElements = {
                        wrapper: element[0],
                        slides: element[0].getElementsByClassName('gallery-item'),
                        images: element[0].getElementsByClassName('slide-img'),
                        navEl
                    };

                    scope.current = scope.items[0];

                    $window.addEventListener('resize', setSliderDimensions);
                    $window.dispatchEvent(new Event('resize'));

                    scope.$on('$destroy', () => {
                        $window.removeEventListener('resize', setSliderDimensions);
                    });

                    imagesLoaded(scope.domElements.images, { background: true }, () => {
                        const gallery = new Gallery(scope.domElements.wrapper.querySelector('.gallery-container'));
                        scope.isReady = true;
                    });
                }
            }
        };
    });
})();
