(function () {
    angular.module('alterWebApp').controller('SingleNewsController', SingleNewsController);

    function SingleNewsController($scope, $q, $rootScope, $state, $filter, $stateParams, $timeout, FileService, News, Utils, NewsService) {
        const self = this;
        self.isReady = false;
        self.news = null;
        self.images = [];
        self.latestNews = [];
        self.galleryRatio = ($rootScope.isMobile ? '3:2' : '3:1');
        self.currentUrl = ''; 

        self.initView = () => {
            if (angular.isUndefined($stateParams.slug)) {
                $state.go('home');
            }

            NewsService.get($stateParams.slug)
                .then((data) => {
                    const news = new News(data.data.data);
                    self.news = news;

                    if (news.slug !== $state.params.slug) {
                        $state.go('app.newsProfile', { lang: $rootScope.currentLanguage.getCode(), slug: news.slug }, { reload: false });
                    }

                    if (typeof news.gallery !== 'undefined' && news.gallery.length) {
                        self.images = buildGallery(news.gallery);
                    }

                    self.currentUrl = Utils.getRoute($state, self.news, $rootScope.currentLanguage.getCode());
                })
                .catch((error) => {
                    console.log('error getting point', error);
                });

            NewsService.getRecentNews(4)
                .then((data) => {
                    if (!data.data.data.length) {
                        return;
                    }

                    self.latestNews = data.data.data.map((e) => {
                        const n = new News(e);
                        n.href = Utils.buildSref(n);
                        return n;
                    });
                })
                .catch((error) => {
                    ;
                });
        };

        self.onCopyClick = ($ev) => {
            $ev.preventDefault();
            Utils.copyToClipboard(self.currentUrl);
        };

        self.getCurrentUrl = () => {
            return Utils.copyToClipboard(self.currentUrl);
        };

        function buildGallery(images) {
            if (!images.length) {
                return [];
            }

            const items = $filter('orderBy')(images, ['-isCoverImage', 'order'])[0];
            const arr = [{ file: FileService.getLandscape(items.file), title: items.title }];

            return arr;
        }
    }
})();
