(function () {
    angular.module('alterWebApp').controller('ServicesController', ServicesController);

    function ServicesController($scope, $q, $state, $rootScope, $stateParams, $timeout, ServicesService, ServiceCategory, Utils) {
        const self = this;
        $scope.currentCategory = { id: 0, title: $rootScope.stringsCollection.allLabel };
        $scope.loading = false;

        $scope.isReady = false;
        $scope.categories = [];

        self.eventsListEl = document.getElementById('servicesList');
        self.nextPage = false;
        self.allFilter = null;

        self.initView = function () {
            $scope.loading = true;
            self.allFilter = new ServiceCategory({
                title: $rootScope.stringsCollection.allLabel,
                slug: null,
                isVisible: true,
                _id: 0
            });

            self.allFilter.href = Utils.buildSref(self.allFilter);
            $scope.categories.unshift(self.allFilter);

            ServicesService.getCategories()
                .then((data) => {
                    const docs = data.data.data;
                    if (docs.length) {
                        docs.forEach((ne) => {
                            const obj = new ServiceCategory(ne);
                            obj.href = Utils.buildSref(obj);
                            $scope.categories.push(obj);
                        });

                        if (!angular.isUndefined($stateParams.slug)) {
                            const ctgs = $scope.categories.filter(c => c.slug === $stateParams.slug);
                            if (ctgs.length) {
                                const currentCategory = ctgs[0];
                                if (typeof currentCategory.getId() !== 'undefined' && currentCategory.getId() !== 0) {
                                    $scope.currentCategory = currentCategory;
                                }
                                else {
                                    resetCategory();
                                }
                            }
                            else {
                                resetCategory();
                            }
                        }
                        else {
                            resetCategory();
                        }
                    }
                    $scope.loading = false;
                    $scope.isReady = true;
                })
                .catch((error) => {
                    ;
                    $scope.isReady = true;
                    $scope.loading = false;
                });
        };

        $scope.toggleCategory = (category) => {
            $state.go(category.state, { slug: category.slug }, { reload: true });
        };

        function resetCategory() {
            $scope.currentCategory = self.allFilter;
            $state.transitionTo('app.services.servicesCategory', { slug: null }, { location: true, inherit: true, relative: $state.$current, notify: false });
        }
    }
})();
