(function () {
    angular.module('alterWebApp').directive('mobileMonthFilter', ($rootScope, $timeout, GeneralConst, Utils) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                filter: '=',
                callback: '='
            },
            templateUrl: 'app/shared/mobile-month-filter/template.html',
            link: (scope, element) => {
                if (!scope.callback) {
                    return;
                }

                scope.isReady = false;
                scope.selectedMonthString = '';

                scope.values = {
                    minDate: null,
                    maxDate: null,
                    current: null
                };

                scope.DOM = {
                    datePicker: document.getElementById('currentMonth')
                };

                scope.$watch('filter', () => {
                    scope.values.minDate = ((scope.filter && scope.filter.minDate && moment(scope.filter.minDate.toString()).isValid())
                        ? moment(scope.filter.minDate).format('YYYY-MM')
                        : moment('2018-01-01').format('YYYY-MM')
                    );

                    scope.values.maxDate = ((scope.filter && scope.filter.maxDate && moment(scope.filter.maxDate.toString()).isValid())
                        ? moment(scope.filter.maxDate).format('YYYY-MM')
                        : moment().add(1, 'year').format('YYYY-MM')
                    );

                    if (!scope.filter.current || !moment(scope.filter.current).isValid()) {
                        if (scope.filter.current === GeneralConst.ALL_ENUM) {
                            scope.values.current = null;
                            scope.selectedMonthString = $rootScope.stringsCollection.allLabel;
                        }
                        else {
                            scope.values.current = moment().toDate('YYYY-MM');
                            scope.selectedMonthString = formatDateString();
                        }
                    }
                    else {
                        scope.values.current = moment(scope.filter.current).toDate('YYYY-MM');
                        scope.selectedMonthString = formatDateString(scope.values.current);
                    }
                }, true);


                scope.openCalendar = (ev) => {
                    ev.preventDefault();
                    if (!scope.DOM.datePicker) {
                        return;
                    }

                    scope.DOM.datePicker.focus();
                    $timeout(() => {
                        scope.DOM.datePicker.click();
                    });
                };

                scope.onChange = (ev) => {
                    const dt = scope.values.current;
                    scope.selectedMonthString = formatDateString(dt);
                    const year = moment(dt).format('YYYY');
                    const month = moment(dt).format('M');
                    scope.callback(month, year);
                };

                function formatDateString(dt) {
                    return `${Utils.capitalize(moment(dt).format('MMM'))} ${moment(dt).format('YYYY')}`;
                }
            }
        };
    });
})();
