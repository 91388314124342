(function () {
    angular.module('alterWebApp').service('AppService', AppService);

    function AppService($window, $rootScope, ApiService) {
        const baseUrlLocal = '/assets/languages/';
        const baseUrl = '/api/v1';

        this.getDefaultPlaceholder = function () {
            return 'assets/img/facebook_share.png';
        };

        this.getTitles = function (code) {
            return ApiService.getLocalFile(baseUrlLocal + code + '/titles.json');
        };

        this.getMainInfo = function (code) {
            return ApiService.getPublic(baseUrl + '/config/' + code);
        };
    }
})();
