(function () {
  angular.module("alterWebApp").controller("MapController", MapController);

  function MapController(
    $scope,
    $state,
    $rootScope,
    $timeout,
    $window,
    LanguageService,
    Utils,
    Point,
    PointsService,
    GeneralConst
  ) {
    const self = this;
    self.related = [];
    self.content = null;
    $scope.showFilters = false;
    $scope.isReady = false;
    $scope.config = {};
    $scope.points = [];
    $scope.markers = [];
    $scope.mapHeight = 0;

    self.currentParent = null;
    $scope.selectedPointId = null;
    self.animationInProgress = false;

    self.initView = function () {
      const level = 2;

      PointsService.getPointsTree({ level })
        .then((data) => {
          console.log(data);
          const res = data.data.data;
          for (let i = 0; i < res.length; i += 1) {
            const poi = res[i];
            if (poi.kind === GeneralConst.OUTDOOR_POINT_TYPE) {
              const n = new Point(poi);
              n.href = Utils.buildSref(n, LanguageService.getCurrentLanguage());
              $scope.points.push(n);

              n.childs.forEach((child) => {
                if (child.kind === GeneralConst.OUTDOOR_POINT_TYPE) {
                  $scope.points.push(child);
                }
              });
            }
          }

          $timeout(() => {
            Object.assign($scope.config, $rootScope.mapConfig);
            $scope.config.id = "map" + Math.random().toString(36).substring(3);
            $scope.config.center = null;

            $scope.points.forEach((poi) => {
              if (
                !poi.parent &&
                poi.location &&
                poi.location.coordinates.length
              ) {
                $scope.markers.push(poi.getGeoJSON());
              }
            });

            $scope.isReady = true;
            $timeout(() => {
              $window.dispatchEvent(new Event("resize"));
            }, 200);
          });
        })
        .catch((error) => {
          $window.dispatchEvent(new Event("resize"));
        });

      $window.addEventListener("resize", setMapHeight);
    };

    $scope.onMarkerClick = ($ev, pointId) => {
      if ($ev) {
        $ev.preventDefault();
      }
      onGoToPoint(pointId);
    };

    $scope.setMapPoints = function (selectedPoints, pointId) {
      let points = selectedPoints.map((p) => {
        return p.getGeoJSON();
      });

      points = points.filter((p) => {
        return p !== null;
      });

      $scope.$broadcast("mapDirective:loadLayer", points);
    };

    $scope.resetSelectedPoint = function () {
      self.selectedPoint = null;
      $scope.$broadcast("mapDirective:setSelectedPoint", null);
    };

    $scope.$on("$destroy", () => {
      $window.removeEventListener("resize", setMapHeight);
    });

    function onGoToPoint(pointId) {
      if (pointId == null) {
        goToPoint(null);
        return;
      }

      const point = getPointById(pointId);

      if ($scope.selectedPointId === pointId) {
        $scope.selectedPointId = null;
        const topLevel = $scope.points.filter((poi) => poi.parent === null);
        $scope.resetSelectedPoint();
        $scope.setMapPoints(topLevel, pointId);
        self.animationInProgress = true;
        $timeout(() => {
          self.animationInProgress = false;
          self.currentParent = 0;
        });

        return;
      }

      goToPoint(point);
    }

    function getChildPoints(point) {
      if (!point || !point.childs || !point.childs.length) {
        return [];
      }

      return point.childs;
    }

    function goToPoint(point) {
      const pointId = point == null ? null : point.getId();
      const childs = getChildPoints(point);

      if (childs.length === 0) {
        const url = $state.href(point.state, { slug: point.slug });
        $window.open(url, "_blank");
      } else {
        $scope.selectedPointId = pointId;
        $scope.resetSelectedPoint();
        $scope.setMapPoints(childs, pointId);
        self.animationInProgress = true;
        $timeout(() => {
          self.animationInProgress = false;
          self.currentParent = pointId;
        });
      }
    }

    function getPointById(id) {
      return $scope.points.find((point) => point.id === id);
    }

    function setMapHeight() {
      const nav = document.getElementById("mainNav");
      const navClientHeight = nav ? nav.clientHeight : 0;
      const mapNavH = document.getElementById("mainMapNavigation").clientHeight;

      $timeout(() => {
        $scope.mapHeight = $window.innerHeight - navClientHeight - mapNavH;
        $scope.showFilters = true;
      });
    }
  }
})();
