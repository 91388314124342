(function () {
    angular.module('alterWebApp').controller('EventsController', EventsController);

    function EventsController($scope, $q, $rootScope, $timeout, $filter, $window, Utils, Event, EventsService, GeneralConst) {
        const self = this;
        $scope.listReady = false;
        $scope.related = [];
        $scope.highlighted = [];
        $scope.events = [];
        $scope.eventsListEl = document.getElementById('eventsList');
        $scope.currentYear = moment().format('YYYY');
        $scope.currentMonth = moment().format('M');
        $scope.active = null;
        $scope.nextPage = false;
        $scope.navKey = 'startDate';

        $scope.mainFilter = [];
        $scope.mobileFilters = {
            types: [],
            currentType: {}
        };

        $scope.carouselNavObj = {
            date: 'date',
            title: 'title',
            category: 'category',
            description: 'excerpt'
        };

        $scope.yearFilterSettings = {
            min: 2018,
            max: parseInt($scope.currentYear, 10) + 1
        };

        $scope.otherFilters = [
            {
                name: $rootScope.stringsCollection.permanentLabel,
                value: GeneralConst.PERMANENT_EVENTS_ENUM,
                order: 98,
                class: 'permanent'
            },
            {
                name: $rootScope.stringsCollection.allLabel,
                value: GeneralConst.ALL_ENUM,
                order: 99,
                class: null
            }
        ];

        $scope.searchObj = {
            page: 1,
            limit: 4,
            year: $scope.currentYear,
            month: $scope.currentMonth
        };

        $scope.monthFilter = {
            minDate: null,
            maxDate: null,
            current: null
        };

        self.initView = function () {
            if ($scope.$parent.point) {
                $scope.searchObj.relatedPoint = $scope.$parent.point.id;
            }
            else {
                EventsService.getHighlighted()
                    .then((data) => {
                        const res = data.data.data;
                        if (!res.length) {
                            return;
                        }

                        const highlightedEvents = res.map((ne) => {
                            const obj = new Event(ne);
                            return obj;
                        });

                        $scope.highlighted = $filter('orderBy')(highlightedEvents, ['startDate']);
                    })
                    .catch((error) => {});

                if ($rootScope.isMobile) {
                    filterEvents();
                }
            }

            $scope.mainFilter = Utils.getMonthsArray();
            $scope.mobileFilters.types.push(
                {
                    title: $rootScope.stringsCollection.permanentLabel,
                    id: GeneralConst.PERMANENT_EVENTS_ENUM
                },
                {
                    title: $rootScope.stringsCollection.temporaryLabel,
                    id: GeneralConst.TEMPORARY_EVENTS_ENUM
                }
            );

            $scope.mobileFilters.currentType = $scope.mobileFilters.types[1];
            bindEvents();
        };

        $scope.showFilters = () => {
            return $scope.mobileFilters.currentType && $scope.mobileFilters.currentType.id === GeneralConst.TEMPORARY_EVENTS_ENUM;
        };

        $scope.setDate = (month, year) => {
            if (!month || !year) {
                return;
            }

            if ($scope.searchObj.month === month) {
                return;
            }

            $scope.searchObj.month = month;
            $scope.searchObj.year = year;
            filterEvents(true);
        };

        $scope.setMonth = (ev, month) => {
            ev.preventDefault();
            if ($scope.searchObj.month === month) {
                return;
            }

            $scope.searchObj.month = month;
            filterEvents(true);
        };

        $scope.setYear = (ev, year) => {
            $scope.searchObj.year = year;

            if (ev) { // triggered by user - not an initialization event
                if (year >= $scope.currentYear && $scope.searchObj.month !== GeneralConst.ALL_ENUM && $scope.searchObj.month > $scope.currentMonth) {
                    $scope.searchObj.month = $scope.currentMonth;
                }
            }

            filterEvents(true);
        };

        $scope.triggerTypesSelect = (ev) => {
            ev.preventDefault();
        };

        $scope.toggleType = () => {
            if ($scope.mobileFilters.currentType.id === GeneralConst.PERMANENT_EVENTS_ENUM) {
                $scope.searchObj.month = GeneralConst.PERMANENT_EVENTS_ENUM;
                filterEvents(true);
            }
            else {
                $scope.mobileFilters.currentType = $scope.mobileFilters.types[1];
                $scope.searchObj.month = $scope.currentMonth;
                $scope.searchObj.year = $scope.currentYear;
                $scope.monthFilter.current = moment();

                filterEvents(true);
            }
        };

        function filterEvents(reset = true) {
            $scope.loading = true;

            if (reset) {
                $scope.events = [];
                $scope.searchObj.page = 1;
            }

            const deepCloned = JSON.parse(JSON.stringify($scope.searchObj));
            const queryobj = Object.assign({}, $scope.searchObj);
            Object.assign(queryobj, deepCloned);

            if (queryobj && queryobj.month === GeneralConst.ALL_ENUM) {
                if ($rootScope.isMobile) {
                    queryobj.year = undefined;
                    queryobj.month = undefined;
                }
                else {
                    queryobj.month = undefined;
                }
            }

            if (queryobj && queryobj.month === GeneralConst.PERMANENT_EVENTS_ENUM) {
                queryobj.month = undefined;
                queryobj.year = undefined;
                queryobj.type = GeneralConst.PERMANENT_EVENTS_ENUM;
            }

            if (typeof queryobj.month === 'undefined' && typeof queryobj.year === 'undefined') {
                $scope.monthFilter.current = GeneralConst.ALL_ENUM;
            }
            else {
                $scope.monthFilter.current = `${queryobj.year}-${queryobj.month}`;
            }

            EventsService.search(queryobj)
                .then((data) => {
                    const res = data.data.data;

                    if (res.total) {
                        res.docs.forEach((ne) => {
                            const obj = new Event(ne);
                            obj.href = Utils.buildSref(obj);
                            $scope.events.push(obj);
                        });
                    }

                    if ($scope.listReady && reset && document.getElementById('secundaryFixedBar').classList.contains('fixed')) {
                        const bodyRect = document.body.getBoundingClientRect();
                        const elemRect = $scope.eventsListEl.getBoundingClientRect();
                        const offset = elemRect.top - bodyRect.top;

                        const y = offset - $scope.navH;
                        TweenMax.to($window, 0.6, {
                            scrollTo: { y, autoKill: true },
                            ease: 'Power1.easeOut',
                            autoKill: true,
                            overwrite: 5
                        });
                    }

                    $scope.searchObj.page += 1;
                    $scope.nextPage = $scope.searchObj.page <= res.pages;
                    $scope.loading = false;

                    if (!$scope.listReady) {
                        $scope.listReady = true;
                    }
                })
                .catch((error) => {});
        }

        function paginate() {
            if (!$scope.nextPage) {
                return;
            }

            filterEvents(false);
        }

        function bindEvents() {
            angular.element($window).on('scroll', scrollEventListener);

            $scope.$on('$destroy', () => {
                angular.element($window).off('scroll', scrollEventListener);
            });
        }

        function scrollEventListener() {
            if (!$scope.loading && Utils.closeToBottom($scope.eventsListEl)) {
                paginate();
            }

            if (!$scope.navH) {
                const nav = document.getElementById('mainNav');
                const navClientHeight = (nav ? nav.clientHeight : 0);
                $scope.navH = (nav && nav.classList.contains('no-bg') ? 0 : navClientHeight);
            }

            if ($rootScope.isMobile) {
                const secundaryBar = document.getElementById('secundaryFixedBar');
                if (!secundaryBar) {
                    return;
                }

                const rect = $scope.eventsListEl.getBoundingClientRect();

                if (!$scope.cardsListOffsetY) {
                    $scope.cardsListOffsetY = rect.top;
                }

                const shouldHideBar = Utils.closeToBottom(document.getElementById('footer'), 20);
                if (!secundaryBar.classList.contains('fixed') && !shouldHideBar && (window.pageYOffset + 50 + $scope.navH > $scope.cardsListOffsetY)) {
                    if (rect.top + $scope.eventsListEl.clientHeight < $scope.navH) {
                        return;
                    }
                    secundaryBar.classList.add('fixed');
                }
                else if ((window.pageYOffset + 50 + $scope.navH < $scope.cardsListOffsetY) || rect.top + $scope.eventsListEl.clientHeight < $scope.navH) {
                    secundaryBar.classList.remove('fixed');
                }
            }
        }
    }
})();
