(function () {
    angular.module('alterWebApp').directive('cardDirective', ($rootScope, $state, $window, FileService, GeneralConst, Utils, $timeout) => {
        return {
            restrict: 'E',
            transclude: false,
            scope: {
                data: '=',
                full: '=',
                share: '=',
                contacts: '=',
                state: '=',
                last: '='
            },
            templateUrl: 'app/shared/generic-card/template.html',
            link: (scope, element, attrs) => {
                scope.isReady = false;
                scope.styles = {
                    maxTitleLength: (scope.full ? 120 : 45)
                };

                scope.getThumbnail = (fileId) => {
                    if (!fileId) {
                        return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.SQUARE);
                    }
                    return FileService.getThumbnail(fileId);
                };

                scope.$watch('data', init, true);

                function init() {
                    if (!scope.data) {
                        return;
                    }

                    if (!scope.data.title) {
                        return;
                    }

                    if (scope.data.description) {
                        const length = scope.full ? 200 : 120;
                        scope.data.description = Utils.trimText(scope.data.description, length);
                    }

                    scope.isReady = true;
                    if (!scope.share) {
                        return;
                    }

                    $timeout(() => {
                        const tips = element[0].getElementsByClassName('share-btn');
                        if (tips.length) {
                            for (let i = 0; i < tips.length; i += 1) {
                                const content = tips[i].getAttribute('data-tippy-content');

                                if (content) {
                                    tippy(tips[i], { content });
                                    const currentTip = tips[i]._tippy;
                                    tips[i].addEventListener('click', () => {
                                        currentTip.setContent(tips[i].getAttribute('data-tippy-click-content'));
                                        setTimeout(() => {
                                            currentTip.show();
                                            setTimeout(() => {
                                                currentTip.setContent(content);
                                            }, 3500);
                                        }, 10);
                                    });
                                }
                            }
                        }
                    });
                }
            }
        };
    });
})();
