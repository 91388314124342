(function () {
    angular.module('alterWebApp').controller('AppController', AppController);

    function AppController($scope, $http, $stateParams, $state, $rootScope, $window, AppService, LanguageService, Language, Utils) {
        const self = this;
        $scope.menuReady = $rootScope.menuReady;
        self.appReady = false;
        self.systemLanguages = [];

        self.initApp = function () {
            LanguageService.init((error) => {
                if (error) {
                    self.serverErrorCallback(error);
                }

                async.series([
                    (callback) => {
                        return Utils.loadMomentLocaleByCode($rootScope.currentLanguage.getCode(), (err) => {
                            if (err) {
                                return callback(err);
                            }

                            moment.locale($rootScope.currentLanguage.getCode());
                            return callback();
                        });
                    },
                    (callback) => {
                        return resetLanguageObjects(callback);
                    },
                    (callback) => {
                        return getGlobals(callback);
                    },
                    (callback) => {
                        return bindEvents(callback);
                    }
                ], (err) => {
                    if (err) {
                        self.serverErrorCallback(new Error('Init Languages / Globals' + ($rootScope.ENV.dev ? err : '')));
                        return;
                    }

                    self.appReady = true;

                    fbAsyncInit(() => {
                        FB.init({
                            appId: $rootScope.ENV.fbAppId,
                            status: true,
                            cookie: true,
                            xfbml: true,
                            version: 'v2.4'
                        });
                    });
                });
            });
        };

        $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
            if (toState.name !== $rootScope.baseState && !toParams.lang) {
                const currentParams = Object.assign({}, toParams);
                currentParams.lang = LanguageService.getCurrentLanguage();
                $state.go(toState.name, currentParams);
            }
            else if (toState.name === $rootScope.baseState && toParams.lang === LanguageService.getDefaultLanguage().isoCode) {
                $state.go($state.current, { lang: undefined }, { reload: false });
            }
        });

        self.serverErrorCallback = (error) => {
            console.log('serverError', error);
        };

        $rootScope.trimText = (str, length) => {
            return Utils.trimText(str, length);
        };

        function fbAsyncInit(callback) {
            let js = null;
            const id = 'facebook-jssdk';
            const ref = document.getElementsByTagName('script')[0];

            if (document.getElementById(id)) {
                return;
            }

            js = document.createElement('script');
            js.id = id;
            js.async = true;
            js.src = '//connect.facebook.net/en_US/all.js';

            if(js.readyState) {
                js.onreadystatechange = function() {
                    if ( js.readyState === "loaded" || js.readyState === "complete" ) {
                        js.onreadystatechange = null;
                        callback();
                    }
                };
            }
            else {
                js.onload = function() {
                    callback();
                };
            }

            ref.parentNode.insertBefore(js, ref);
        }

        function resetLanguageObjects(done) {
            const isoCode = $rootScope.currentLanguage.getCode();
            return AppService.getTitles(isoCode)
                .then((data) => {
                    $rootScope.stringsCollection = data.data;
                    return done();
                })
                .catch((err) => {
                    return done(err);
                });
        }

        function getGlobals(done) {
            const isoCode = $rootScope.currentLanguage.getCode();

            return AppService.getMainInfo(isoCode)
                .then((data) => {
                    $rootScope.mainLogo = data.data.data.mainLogo;
                    $rootScope.socialNetworks = data.data.data.socialNetworks;
                    $rootScope.appInfo = data.data.data.appInfo;
                    $rootScope.mainInfo = data.data.data.mainInfo;
                    $rootScope.contacts = data.data.data.contacts;
                    $rootScope.footerLogos = data.data.data.footerLogos;
                    return done();
                })
                .catch((e) => {
                    return done(e);
                });
        }

        function bindEvents(done) {
            $window.addEventListener('resize', checkMobile);

            $scope.$on('$destroy', () => {
                $window.removeEventListener('resize', checkMobile);
            });

            $window.dispatchEvent(new Event('resize'));

            return done();
        }

        function checkMobile() {
            $rootScope.isMobile = Utils.isMobile();
        }
    }
})();
