(function () {
    angular.module('alterWebApp').factory('Service', ServiceModel);
    angular.module('alterWebApp').factory('ServiceCategory', ServiceCategoryModel);

    function ServiceModel(Utils, $rootScope, $state) {
        function Service(data) {
            if (data) {
                this.setData(data);
            }
        }

        Service.prototype = {
            setData(data) {
                this.id = data._id;
                this.type = 'service';
                this.slug = Utils.initValue(data.slug);
                this.title = Utils.initValue(data.title);
                this.description = Utils.initValue(data.description);
                this.excerpt = Utils.initValue(data.description);
                this.contacts = Utils.initValue(data.contacts);
                this.state = 'app.services.servicesCategory';
            },
            getId() {
                return this.id;
            }
        };

        return Service;
    }


    function ServiceCategoryModel(Utils, $rootScope, $state) {
        function ServiceCategory(data) {
            if (data) {
                this.setData(data);
            }
        }

        ServiceCategory.prototype = {
            setData(data) {
                this.id = data._id;
                this.slug = Utils.initValue(data.slug);
                this.title = Utils.initValue(data.title);
                this.state = 'app.services.servicesCategory';
            },
            getId() {
                return this.id;
            }
        };

        return ServiceCategory;
    }
})();
