(function () {
    angular.module('alterWebApp').factory('Highlight', HighlightModel);

    function HighlightModel(Utils, $rootScope, $stateParams, $state) {
        function Highlight(data) {
            if (data) {
                this.setData(data);
            }
        }

        Highlight.prototype = {
            setData(data) {
                this.id = data._id;
                this.title = Utils.initValue(data.title);
                this.excerpt = Utils.initValue(data.excerpt);
                this.gallery = Utils.initValue(data.image);
                this.href = Utils.initValue(data.link);
                this.order = Utils.initValue(data.order);
                this.createdAt = Utils.initValue(data.createdAt);

                this.category = $rootScope.stringsCollection.highlightLabel;
                this.image = this.getCoverImage();
            },
            getId() {
                return this.id;
            },
            getCoverImage() {
                if (!this.gallery) {
                    return null;
                }

                return this.gallery.file;
            }
        };

        return Highlight;
    }
})();
