
(function () {
    angular.module('alterWebApp').service('NewsletterService', NewsletterService);

    function NewsletterService(ApiService) {
        const baseUrl = '/api/v1/newsletter';

        this.subscribe = function (obj) {
            return ApiService.postPublic(baseUrl + '/subscribe', obj);
        };

        this.unsubscribe = function (token) {
            return ApiService.delete(baseUrl + '/unsubscribe/' + token);
        };
    }
})();
