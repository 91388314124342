(function () {
    angular.module('alterWebApp').controller('MainNucleusController', MainNucleusController);

    function MainNucleusController($scope, $state, $rootScope, $stateParams, Point, PointsService, $filter, Utils, LanguageService, GeneralConst, FileService) {
        const self = this;
        $scope.isReady = false;
        $scope.isMobile = $rootScope.isMobile;
        $scope.point = null;
        $scope.currentState = $state.current.name;

        self.menus = [];
        self.images = [];
        self.galleryRatio = ($rootScope.isMobile ? '3:2' : '3:1');

        $scope.$on('$stateChangeSuccess', (event, toState) => {
            $scope.currentState = toState.name;
        });

        self.initView = () => {
            if (angular.isUndefined($stateParams.slug)) {
                $state.go('home');
            }

            PointsService.get($stateParams.slug)
                .then((data) => {
                    const point = new Point(data.data.data);
                    const currentIsoCode = LanguageService.getCurrentLanguage();
                    $scope.point = point;

                    self.menus = [{
                        href: $state.href('app.nucleusMain.nucleusVisit', { slug: point.slug, lang: currentIsoCode }),
                        state: 'app.nucleusMain.nucleusVisit',
                        label: $rootScope.stringsCollection.visitMenuLabel
                    }, {
                        href: $state.href('app.nucleusMain.nucleusNews', { slug: point.slug, lang: currentIsoCode }),
                        state: 'app.nucleusMain.nucleusNews',
                        label: $rootScope.stringsCollection.newsMenuLabel
                    }, {
                        href: $state.href('app.nucleusMain.nucleusEvents', { slug: point.slug, lang: currentIsoCode }),
                        state: 'app.nucleusMain.nucleusEvents',
                        label: $rootScope.stringsCollection.eventsMenuLabel
                    }, {
                        href: $state.href('app.nucleusMain.nucleusContacts', { slug: point.slug, lang: currentIsoCode }),
                        state: 'app.nucleusMain.nucleusContacts',
                        label: $rootScope.stringsCollection.contactsMenuLabel
                    }, {
                        href: $state.href('app.nucleusMain.nucleusPricing', { slug: point.slug, lang: currentIsoCode }),
                        state: 'app.nucleusMain.nucleusPricing',
                        label: $rootScope.stringsCollection.pricingMenuLabel
                    }];

                    $scope.point.state = 'app.pointProfile';
                    $scope.point.href = Utils.buildSref($scope.point, currentIsoCode);

                    if (point.slug !== $state.params.slug) {
                        $state.go('app.nucleusMain.nucleusVisit', { lang: $rootScope.currentLanguage.getCode(), slug: point.slug }, { reload: false });
                    }

                    if (typeof point.gallery !== 'undefined' && point.gallery.length) {
                        self.images = self.buildGallery(point.gallery);
                    }

                    $scope.isReady = true;
                })
                .catch((error) => {
                    console.log('error getting point', error);
                });
        };

        self.buildGallery = (images) => {
            if (!images.length) {
                return [];
            }

            const items = images.map((img) => {
                if (!img.file) {
                    return FileService.getDefaultImageUrl(GeneralConst.IMAGE_SIZE.LANDSCAPE);
                }

                img.file = FileService.getLandscape(img.file);
                return img;
            });

            return $filter('orderBy')(items, ['-isPanorama', 'order']);
        };
    }
})();
