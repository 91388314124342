(function () {
    angular.module('alterWebApp').factory('RequestInterceptor', requestInterceptor);

    function requestInterceptor($window, $q, $rootScope) {
        return {
            request(config) {
                const isoCode = $rootScope.currentLanguage ? $rootScope.currentLanguage.getCode() : null;
                if (isoCode) {
                    config.params = config.params || {};
                    config.params.isoCode = isoCode;
                }

                return config || $q.when(config);
            }
        };
    }
})();
