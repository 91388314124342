(function () {
    angular.module('api-service', []).service('ApiService', ApiService);

    function ApiService($http, $httpParamSerializerJQLike) {
        let protocol = window.location.protocol + '//';
        let host = window.location.host;
        let token = null;

        this.setProtocol = function (p) {
            if (!p) {
                return;
            }

            protocol = p;
        };

        this.setHost = function (h) {
            if (!h) {
                return;
            }

            host = h;
        };

        this.setToken = function (t) {
            if (!t) {
                return;
            }

            token = t;
        };

        this.getToken = function () {
            return token;
        };

        this.getHost = function () {
            return host;
        };

        this.getFullHost = function () {
            return protocol + host;
        };

        this.getLocalFullHost = function () {
            return protocol + window.location.host;
        };

        this.getAuthorizationHeaderJWT = function () {
            return { Authorization: 'Bearer ' + token };
        };

        this.getLocalFile = function (path) {
            return $http.get(path);
        };

        this.getPublic = function (url) {
            return $http.get(protocol + host + url);
        };

        this.postPublic = function (url, object) {
            return $http({
                method: 'POST',
                url: protocol + host + url,
                data: $httpParamSerializerJQLike(object),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        };

        this.get = function (url) {
            return $http({
                method: 'GET',
                url: protocol + host + url,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + token
                }
            });
        };

        this.query = function (url, params) {
            return $http({
                method: 'GET',
                url: protocol + host + url,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + token
                },
                params
            });
        };

        this.post = function (url, object) {
            return $http({
                method: 'POST',
                url: protocol + host + url,
                data: $httpParamSerializerJQLike(object),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + token
                }
            });
        };

        this.delete = function (url) {
            return $http({
                method: 'DELETE',
                url: protocol + host + url,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        };

        this.put = function (url, object) {
            return $http({
                method: 'PUT',
                url: protocol + host + url,
                data: $httpParamSerializerJQLike(object),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + token
                }
            });
        };

        this.uploadFile = function (url, formData) {
            return $http.post(protocol + host + url, formData, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                    Authorization: 'Bearer ' + token
                }
            });
        };
    }
})();
