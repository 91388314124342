(function () {
    angular.module('alterWebApp').controller('NewsController', NewsController);

    function NewsController($scope, $q, $rootScope, $window, News, NewsService, Utils, GeneralConst) {
        const self = this;
        $scope.listReady = false;
        $scope.highlighted = [];
        $scope.news = [];
        $scope.newsListEl = document.getElementById('newsList');
        $scope.currentYear = moment().format('YYYY');
        $scope.currentMonth = moment().format('M');
        $scope.active = null;
        $scope.nextPage = false;

        $scope.mainFilter = [];

        $scope.yearFilterSettings = {
            min: 2018,
            max: parseInt($scope.currentYear, 10)
        };

        $scope.otherFilters = [{
            name: $rootScope.stringsCollection.allLabel,
            value: GeneralConst.ALL_ENUM,
            order: 99
        }];

        $scope.searchObj = {
            page: 1,
            limit: 4,
            year: $scope.currentYear,
            month: $scope.currentMonth
        };

        $scope.monthFilter = {
            minDate: null,
            maxDate: moment(),
            current: null
        };

        self.initView = function () {
            if ($scope.$parent.point) {
                $scope.searchObj.relatedPoint = $scope.$parent.point.id;
            }
            else {
                NewsService.getHighlighted()
                    .then((data) => {
                        const res = data.data.data;
                        if (!res.length) {
                            return;
                        }

                        $scope.highlighted = res.map((ne) => {
                            const obj = new News(ne);
                            return obj;
                        });
                    })
                    .catch((error) => {
                        ;
                    });
            }

            $scope.mainFilter = Utils.getMonthsArray();

            if ($rootScope.isMobile) {
                filterNews();
            }

            bindEvents();
        };

        $scope.setMonth = (ev, month) => {
            ev.preventDefault();
            if ($scope.searchObj.month === month) {
                return;
            }

            $scope.searchObj.month = month;
            filterNews({ month });
        };

        $scope.setYear = (ev, year) => {
            $scope.searchObj.year = year;

            if (ev) { // triggered by user - not an initialization event
                if (year >= $scope.currentYear && $scope.searchObj.month !== GeneralConst.ALL_ENUM && $scope.searchObj.month > $scope.currentMonth) {
                    $scope.searchObj.month = $scope.currentMonth;
                }
            }

            filterNews();
        };

        $scope.setDate = (month, year) => {
            if (!month || !year) {
                return;
            }

            if ($scope.searchObj.month === month) {
                return;
            }

            $scope.searchObj.month = month;
            $scope.searchObj.year = year;
            filterNews();
        };

        function filterNews(filters, reset = true) {
            $scope.loading = true;

            if (reset) {
                $scope.news = [];
                $scope.searchObj.page = 1;
            }

            const deepCloned = JSON.parse(JSON.stringify($scope.searchObj));
            const queryobj = Object.assign({}, $scope.searchObj);
            Object.assign(queryobj, deepCloned);

            if (queryobj && queryobj.month === GeneralConst.ALL_ENUM) {
                if ($rootScope.isMobile) {
                    queryobj.year = undefined;
                    queryobj.month = undefined;
                }
                else {
                    queryobj.month = undefined;
                }
            }

            if (typeof queryobj.month === 'undefined' && typeof queryobj.year === 'undefined') {
                $scope.monthFilter.current = GeneralConst.ALL_ENUM;
            }
            else {
                $scope.monthFilter.current = `${queryobj.year}-${queryobj.month}`;
            }

            NewsService.search(queryobj)
                .then((data) => {
                    const res = data.data.data;

                    if (res.total) {
                        res.docs.forEach((ne) => {
                            const obj = new News(ne);
                            obj.href = Utils.buildSref(obj);
                            $scope.news.push(obj);
                        });
                    }

                    if ($scope.listReady && reset && document.getElementById('secundaryFixedBar').classList.contains('fixed')) {
                        const bodyRect = document.body.getBoundingClientRect();
                        const elemRect = $scope.newsListEl.getBoundingClientRect();
                        const offset = elemRect.top - bodyRect.top;

                        const y = offset - $scope.navH;
                        TweenMax.to($window, 0.6, {
                            scrollTo: { y, autoKill: true },
                            ease: 'Power1.easeOut',
                            autoKill: true,
                            overwrite: 5
                        });
                    }

                    $scope.searchObj.page += 1;
                    $scope.nextPage = $scope.searchObj.page <= res.pages;
                    $scope.loading = false;

                    if (!$scope.listReady) {
                        $scope.listReady = true;
                    }
                })
                .catch((error) => {
                    ;
                });
        }

        function paginate() {
            if (!$scope.nextPage) {
                return;
            }

            const nextPageFilter = {
                page: $scope.searchObj.page + 1
            };

            filterNews(nextPageFilter, false);
        }
        function bindEvents() {
            angular.element($window).on('scroll', scrollEventListener);

            $scope.$on('$destroy', () => {
                angular.element($window).off('scroll', scrollEventListener);
            });
        }

        function scrollEventListener() {
            if (!$scope.loading && Utils.closeToBottom($scope.newsListEl)) {
                paginate();
            }

            if (!$scope.navH) {
                const nav = document.getElementById('mainNav');
                const navClientHeight = (nav ? nav.clientHeight : 0);
                $scope.navH = (nav && nav.classList.contains('no-bg') ? 0 : navClientHeight);
            }

            if ($rootScope.isMobile) {
                const secundaryBar = document.getElementById('secundaryFixedBar');
                if (!secundaryBar) {
                    return;
                }

                const rect = $scope.newsListEl.getBoundingClientRect();

                if (!$scope.cardsListOffsetY) {
                    $scope.cardsListOffsetY = rect.top;
                }

                const shouldHideBar = Utils.closeToBottom(document.getElementById('footer'), 20);
                if (!secundaryBar.classList.contains('fixed') && !shouldHideBar && (window.pageYOffset + 50 + $scope.navH > $scope.cardsListOffsetY)) {
                    if (rect.top + $scope.newsListEl.clientHeight < $scope.navH) {
                        return;
                    }
                    secundaryBar.classList.add('fixed');
                }
                else if ((window.pageYOffset + 50 + $scope.navH < $scope.cardsListOffsetY) || rect.top + $scope.newsListEl.clientHeight < $scope.navH) {
                    secundaryBar.classList.remove('fixed');
                }
            }
        }
    }
})();
