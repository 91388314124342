(function () {
    angular.module('alterWebApp').service('SearchService', SearchService);

    function SearchService(ApiService) {
        const baseUrl = '/api/v1/search';

        this.get = function (query) {
            return ApiService.query(baseUrl, query);
        };
    }
})();
